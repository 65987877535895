.progress_container {
  width: 100%;
  height: 10px;
  background: $color-grey-200;
  border-radius: 116.94px;
}

.progress_inner {
  //   width: 50%;
  height: inherit;
  background: $color-primary-600;
  border-radius: inherit;
  transition: width 0.3s ease-in-out;
}
