.campaign_page_container {
  width: 100%;
  min-height: 79vh;
  background: $color-white;

  .campaign_page_container_inner {
    @include grid-columns(1.3fr 2fr, 40px);

    &.block_display {
      display: block;
    }

    @media (max-width: 1140px) {
      gap: 24px;
    }

    @media (max-width: 1050px) {
      display: block;

      &.is_fetching_campaign_data {
        .campaigns_list {
          display: none !important;
        }
      }
    }

    .campaigns_list {
      padding-bottom: 12px;
      min-width: 310px;
      background: $color-grey-50;
      border-radius: 16px;
      height: 120rem;
      &.not_visible {
        display: none;
      }

      @media (max-width: 1050px) {
        display: none;

        &.is_visible {
          display: block;
        }
      }

      .status_sections {
        @include flex-position(space-between, center);
        padding: 20px;
        border-bottom: 1px solid $color-grey-100;

        @include smScreen {
          padding: 16px 12px;
        }

        .side {
          @include flex-position(flex-start, center);
          gap: 30px;

          @media (max-width: 1230px) {
            gap: 16px;
          }

          @media (max-width: 1050px) {
            gap: 30px;
          }

          @include smScreen {
            gap: 18px;
          }

          &:last-child {
            gap: 16px;
          }

          .status {
            font-size: 0.91em;
            font-weight: 500;
            color: $color-grey-500;
            cursor: pointer;

            &.active {
              font-weight: 700;
              color: $color-primary-600;
            }
          }

          .icon {
            @include flex-position(center, center);
            cursor: pointer;
          }
        }
      }

      .layout {
        height: auto;
      }

      .list_layout {
        padding: 16px 20px;

        &.scroll-height {
          height: 115rem;
          overflow: auto;
        }

        @include smScreen {
          padding: 16px 12px;
        }

        .date_title {
          margin-top: 28px;
          margin-bottom: 10px;
          font-size: 0.95em;
          font-weight: 600;
          color: $color-grey-500;

          &:first-child {
            margin-top: 0;
          }
        }

        .list {
          .row {
            @include flex-position(space-between, center);
            gap: 8px;
            padding: 20px 16px;
            border-radius: 12px;

            &.active {
              background: $color-white;
            }

            .left {
              @include flex-position(flex-start, center);
              gap: 8px;

              .form_group_container {
                margin: 0;
              }

              .title {
                @include ellipsisHeight(1);
                font-size: 0.95em;
                font-weight: 700;
                color: $color-grey-900;
                cursor: pointer;
              }
            }

            .time {
              font-size: 0.95em;
              font-weight: 400;
              color: $color-grey-500;
              text-wrap: nowrap;
            }
          }
        }
      }

      .grid_layout {
        .row {
          padding: 12px;
          border-bottom: 1px solid $color-grey-100;

          &:last-child {
            margin: 0;
            border: none;
          }

          &.active {
            .row_inner {
              background: $color-white;
            }
          }

          .row_inner {
            padding: 20px;
            border-radius: 16px;

            .top {
              @include flex-position(space-between, center);
              gap: 8px;
              margin-bottom: 12px;

              .title {
                @include ellipsisHeight(1);
                font-size: 1em;
                font-weight: 700;
                color: $color-grey-900;
                cursor: pointer;
              }

              .time {
                font-size: 0.85em;
                font-weight: 400;
                color: $color-grey-500;
                text-wrap: nowrap;
              }
            }

            .subject {
              @include ellipsisHeight(1);
              margin-bottom: 4px;
              font-size: 0.85em;
              font-weight: 700;
              color: $color-grey-500;
            }

            .content {
              @include ellipsisHeight(3);
              font-size: 0.85em;
              font-weight: 400;
              color: $color-grey-500;
              line-height: 150%;
            }
          }
        }
      }
    }

    .campaign_details {
      // width: 100%;
      &.not_visible {
        display: none;
      }

      .start_btn {
        background: $color-primary-600;
        color: $color-white;
        border: none;
        font-size: 12px;
        height: 48px;
      }

      .preview {
        background: $color-success;
        color: $color-white;
        font-size: 10px;
        border: none;
      }

      .campaign_report {
        width: 100%;
        .actions {
          @include flex-position(flex-end, center);
          gap: 1rem;
          .back_btn {
            font-size: 12px;
          }
        }
        .report_content {
          margin-bottom: 36px;
          padding: 22px 0 48px 0;
          border: 1px solid $color-grey-100;
          border-left: none;
          border-right: none;

          .check_back {
            margin-bottom: 48px;
            font-style: italic;

            span {
              font-weight: 500;
            }
          }

          .sent {
            background: #d4eaff !important;
          }
          .scheduled {
            background: #d9e4fd !important;
          }
          .delivered {
            background: #a9c4ff !important;
          }
          .bounced {
            background: #ffa6a1 !important;
          }
          .reach {
            background: #ffe4b7 !important;
          }
          .clicks {
            background: #fab1d5 !important;
          }
          .opened {
            background: #a6f2c5 !important;
          }
          .unsubscribed {
            background: #38bdf8 !important;
          }
          .open_rate {
            background: #d4eaff !important;
          }
          .pending {
            background: #0f172a !important;
            color: $color-white !important;
          }

          .top {
            @include flex-position(space-between, center);
            gap: 12px;
            flex-wrap: wrap;
            margin-bottom: 24px;

            p {
              font-size: 1.02em;
              font-weight: 500;
              color: $color-grey-900;
            }

            .form_group_container {
              margin: 0 0 0 auto;
              padding: 6px 10px;
              padding-right: 6px;
              font-size: 0.9em;
              width: fit-content;
              background: $color-primary-600;
              border: 1px solid $color-primary-600 !important;
              border-radius: 12px;

              @include xsmdScreen {
                align-self: flex-start;
              }

              .form-group {
                font-size: 1.05em;
                width: max-content;

                .select_container__control {
                  padding: 0;
                  background: none !important;
                  border: none !important;

                  .select_container__input {
                    color: $color-white !important;
                  }

                  svg path {
                    fill: $color-white !important;
                  }

                  .select_container__single-value {
                    color: $color-white !important;
                  }
                }
              }
            }
          }

          .graph_data {
            @include flex-position(flex-start, flex-start);
            gap: 2rem;

            .bottom {
              width: 100%;

              .accordion_container {
                margin-bottom: 24px;

                &:last-child {
                  margin: 0;
                }

                .accordion_content {
                  .header {
                    // @include grid-columns(1fr 1fr 1fr 1fr, 16px);
                    @include flex-position(space-between, center);
                    flex-wrap: wrap;
                    margin: 0;
                    width: 100%;
                    gap: 2rem;

                    @media (max-width: 1200px) {
                      @include grid-columns(1fr 1.5fr, 3rem);
                      // @include flex-position(space-between, center);
                    }

                    @media (max-width: 1050px) {
                      @include grid-columns(1fr 1.5fr 1fr 1fr, 16px);
                    }

                    @media (max-width: 1017px) {
                      @include grid-columns(1fr 1.5fr, 20px);
                    }

                    @media (max-width: 480px) {
                      @include grid-columns(1fr, 20px);
                    }

                    .info {
                      .title {
                        margin-bottom: 12px;
                        font-size: 12px;
                        font-weight: 500;
                        color: $color-grey-400;
                      }

                      .value {
                        padding: 9px 8px;
                        font-size: 12px;
                        font-weight: 700;
                        width: fit-content;
                        color: $color-grey-900;
                        background: $color-grey-50;
                        border-radius: 8px;
                      }

                      .customers {
                        @include flex-position(flex-start, center);

                        img {
                          margin-left: -5px;
                          width: 32px;
                          height: 32px;
                          border: 1.5px solid $color-white;
                          border-radius: 50%;
                          object-fit: contain;

                          &:first-child {
                            margin-left: 0px;
                          }
                        }

                        p {
                          @include flex-position(center, center);
                          margin-left: -5px;
                          width: 32px;
                          height: 32px;
                          font-size: 0.7em;
                          font-weight: 600;
                          color: $color-grey-900;
                          background: $color-grey-200;
                          border: 1.5px solid $color-white;
                          border-radius: 50%;
                        }
                      }

                      &.segments {
                        ul {
                          padding-left: 16px;

                          li {
                            margin-bottom: 6px;
                            font-size: 0.85em;
                            font-weight: 500;
                            color: $color-grey-500;
                            line-height: 130%;
                            list-style: disc;

                            &:last-child {
                              margin: 0;
                            }
                          }
                        }
                      }

                      .date {
                        @include flex-position(flex-start, center);
                        gap: 4px;
                        padding: 9px 8px;
                        font-size: 0.8em;
                        font-weight: 600;
                        width: fit-content;
                        color: $color-grey-900;
                        background: $color-grey-50;
                        border-radius: 8px;

                        svg {
                          margin-top: -1px;

                          path {
                            stroke: $color-grey-900;
                          }
                        }
                      }
                    }
                    .btn_div {
                      padding-top: 2rem;
                      @include flex-position(center, center);
                      width: 100%;
                      font-weight: 700;
                      font-size: 12px;
                    }
                  }
                }
              }
            }
            .chart {
              padding-top: 12px;
              .title {
                font-size: 0.85em;
                font-weight: 700;
                margin-bottom: 1rem;
              }
              canvas {
                width: 100% !important;
                height: auto !important;
              }
            }
          }
          .report_explanation {
            margin-top: 3rem;
            .data_item {
              @include flex-position(flex-start, center);
              gap: 1rem;
              margin-bottom: 12px;
              .bullet {
                width: 10px;
                height: 10px;
                border-radius: 50%;
              }
              p {
                font-size: 12px;
                font-weight: 400;
              }
            }
          }
        }
      }

      .campaign_schedule {
        padding: 12px 16px;
        .schedule_details {
          @include flex-position(space-between, center);
          // margin-bottom: 2.5rem;
          .item {
            @include flex-position(flex-start, flex-start);
            flex-direction: column;
            align-self: flex-start;
            gap: 10px;
            .title {
              color: $color-grey-400;
              font-size: 12px;
              font-weight: 500;
            }
            .segment_name {
              color: $color-primary-600;
              font-size: 12px;
              font-weight: 500;
              margin-top: 0.5rem;
            }
            .date {
              @include flex-position(flex-start, center);
              background: $color-grey-100;
              border-radius: 8px;
              padding: 8px;
              gap: 8px;

              p {
                font-size: 12px;
                font-weight: 600;
              }
            }
          }
          button {
            font-size: 12px;
            margin-top: 1.2rem;
          }
        }
      }

      .section_header_container {
        margin-bottom: 20px;

        .side:first-child .icon {
          display: none;

          @media (max-width: 1050px) {
            display: flex;
          }
        }

        .title {
          font-size: 1.35em;
        }

        svg {
          width: 24px;
          height: 24px;

          path {
            stroke: $color-grey-500;
          }
        }
      }

      .header {
        @include flex-position(space-between, center);
        gap: 12px;
        margin-bottom: 30px;

        .side {
          &:last-child {
            @include flex-position(flex-end, center);
            gap: 24px;
          }

          .product {
            @include flex-position(flex-start, center);
            margin-bottom: 4px;

            p {
              font-size: 0.95em;
              font-weight: 500;
              color: $color-grey-500;

              span {
                font-weight: 600;
                color: $color-grey-500;

                &.link {
                  color: $color-primary-600;
                  cursor: pointer;
                }
              }
            }

            .dot {
              margin-left: 8px;
              width: 6.5px;
              height: 6.5px;
              background: $color-grey-200;
              border-radius: 50%;
            }
          }

          .recipient {
            @include flex-position(flex-start, center);

            p {
              font-size: 0.95em;
              font-weight: 600;
              color: $color-grey-500;
            }

            .to {
              margin-right: 2px;
            }

            .form_group_container {
              .select_container__control {
                margin-top: 2px;
                padding: 0;
                border: none !important;

                .select_container__single-value {
                  font-weight: 600 !important;
                }
              }
            }
          }

          .icon {
            @include flex-position(center, center);
            cursor: pointer;
          }
        }
      }

      .content {
        padding: 30px 40px;
        padding-bottom: 35px;
        border: 1px solid $color-grey-100;
        border-radius: 16px;

        @media (max-width: 1230px) {
          padding-left: 16px;
          padding-right: 16px;
        }

        @media (max-width: 1050px) {
          padding-left: 40px;
          padding-right: 40px;
        }

        @include xsmdScreen {
          padding-left: 24px;
          padding-right: 24px;
        }

        @include smScreen {
          padding-left: 16px;
          padding-right: 16px;
        }
        .brand {
          p {
            font-size: 14px;
            line-height: 24px;
            margin-bottom: 1rem;
            color: $color-grey-500;
            margin-bottom: 0;
            strong {
              color: $color-grey-900;
            }
          }
          .tone_select {
            @include flex-position(space-between, center);
            margin-bottom: 1rem;
            .brand_tags {
              @include flex-position(flex-start, center);
              flex-wrap: wrap;
              gap: 0.8rem;
              border: 1px solid $color-grey-200;
              border-radius: 12px;
              padding: 16px 0px 1.5rem 1.5rem;

              &.flex-column {
                @include flex-position(flex-start, flex-start);
                flex-direction: column;
              }

              &.no-border {
                border: none;
                // padding-top: 1rem;
                padding-left: 0;
                .tag {
                  cursor: pointer;
                }
              }

              .tag {
                @include flex-position(flex-start, center);
                display: inline-flex;
                gap: 4px;
                padding: 5px 8px;
                background: #f9f5ff;
                border-radius: 8px;
                border: 1px solid #e9d7fe;
                cursor: pointer;
                svg {
                  cursor: pointer;
                }
                &.tag_style_1 {
                  background: #f0f9ff;
                  border: 1px solid #b9e6fe;
                  svg path {
                    stroke: #026aa2 !important;
                  }
                }
                &.tag_style_2 {
                  background: #ecfdf3;
                  border: 1px solid #abefc6;
                  svg path {
                    stroke: #067647 !important;
                  }
                }
                &.tag_style_3 {
                  background: #fffaeb;
                  border: 1px solid #fedf89;
                  svg path {
                    stroke: #b54708 !important;
                  }
                }
                &.tag_style_4 {
                  background: #fef3f2;
                  border: 1px solid #fecdca;
                  svg path {
                    stroke: #b42318 !important;
                  }
                }

                .dot {
                  height: 6px;
                  width: 6px;
                  border-radius: 50%;
                  background: #9e77ed;
                  &.tag_style_1 {
                    background: #026aa2;
                  }
                  &.tag_style_2 {
                    background: #067647;
                  }
                  &.tag_style_3 {
                    background: #b54708;
                  }
                  &.tag_style_4 {
                    background: #b42318;
                  }
                }

                p {
                  font-size: 12px;
                  font-weight: 500;
                  color: #9e77ed;
                  margin-bottom: 0;
                  &.tag_style_1 {
                    color: #026aa2;
                  }
                  &.tag_style_2 {
                    color: #067647;
                  }
                  &.tag_style_3 {
                    color: #b54708;
                  }
                  &.tag_style_4 {
                    color: #b42318;
                  }
                }
              }
              .remove_tag {
                @include flex-position(center, center);
              }
              .add_tag {
                @include flex-position(flex-start, center);
                display: inline-flex;
                gap: 5px;
                padding: 5px 8px;
                background: $color-white;
                border-radius: 8px;
                border: 1px solid $color-grey-500;
                cursor: pointer;
                p {
                  font-size: 12px;
                  font-weight: 500;
                  color: $color-grey-500;
                  margin-bottom: 0;
                }
              }
            }
            .tag_select {
              position: relative;
              .tag_select_input {
                @include flex-position(flex-start, center);
                position: relative;
                padding: 0 8px;
                overflow: auto;
                width: 150px;
                height: 40px;
                border: 1px solid $color-grey-200;
                border-radius: 6px;
                cursor: pointer;

                &::after {
                  content: "";
                  position: absolute;
                  background: $color-white;
                  width: 25px;
                  height: 100%;
                  top: 0;
                  right: 0;
                  z-index: 2;
                }
                p {
                  margin-bottom: 0;
                  font-size: 12px;
                }
                svg {
                  position: absolute;
                  top: 11px;
                  right: 5px;
                  z-index: 3;
                }
              }
              .tag_select_dropdown {
                position: absolute;
                top: 50px;
                left: 0;
                width: 130%;
                border: 1px solid $color-grey-200;
                min-height: 100px;
                border-radius: 6px;
                padding: 0 1.5rem;
                background: $color-white;
                z-index: 5;
              }
            }
          }

          .middle-section {
            @include flex-position(space-between, flex-start);
            .writing_approach {
              width: 320px;
              position: relative;
              margin-bottom: 1rem;
              padding-bottom: 24px;

              p {
                margin-bottom: 16px;
              }

              .approach_tag {
                padding: 5px 8px;
                background: #f0f9ff;
                border-radius: 8px;
                border: 1px solid #b9e6fe;
                cursor: pointer;
                transition: 0.2s ease;
                &:hover {
                  transform: scale(1.1);
                }
                p {
                  font-size: 12px;
                  color: #026aa2;
                  margin-bottom: 0;
                }
              }
              .writing_approach_select {
                @include flex-position(flex-start, center);
                position: relative;
                padding: 4px 8px;
                overflow: auto;
                width: 100%;
                height: 45px;
                border: 1px solid $color-grey-200;
                border-radius: 6px;
                cursor: pointer;

                &::after {
                  content: "";
                  position: absolute;
                  background: $color-white;
                  width: 25px;
                  height: 100%;
                  top: 0;
                  right: 0;
                  z-index: 2;
                }
                p {
                  margin-bottom: 0;
                  font-size: 12px;
                  color: $color-grey-500;
                }
                svg {
                  position: absolute;
                  top: 11px;
                  right: 5px;
                  z-index: 3;
                }
              }
              .approach_select_dropdown {
                @include flex-position(flex-start, flex-start);
                flex-direction: column;
                gap: 1rem;
                position: absolute;
                top: 100px;
                left: 0;
                width: 100%;
                border: 1px solid $color-grey-200;
                min-height: 100px;
                border-radius: 6px;
                padding: 1.5rem;
                background: $color-white;
                z-index: 5;
              }
            }

            .campaign_sender {
              margin-top: 8px;
              width: 114px;
              position: relative;
              .campaign_sender_select {
                @include flex-position(center, center);
                background: $color-primary-600;
                width: 100%;
                height: 40px;
                border-radius: 6px;
                gap: 8px;
                cursor: pointer;
                p {
                  color: $color-white;
                  font-size: 14px;
                  font-weight: 700;
                }
                svg path {
                  stroke: $color-white;
                }
              }
              .campaign_sender_dropdown {
                @include flex-position(center, flex-start);
                flex-direction: column;
                position: absolute;
                top: 45px;
                left: 0;
                width: 100%;
                border: 1px solid $color-grey-200;
                border-radius: 6px;
                padding: 8px;
                min-height: 75px;
                .campaign_sender_dropdown_item {
                  cursor: pointer;
                  padding: 0px 5px;
                  border-radius: 5px;
                  width: 100%;
                  &.active {
                    background: $color-grey-100;
                  }
                  &:hover {
                    background: $color-grey-100;
                  }
                  p {
                    font-size: 12px;
                    font-weight: 500;
                    color: $color-grey-900;
                  }
                }
              }
            }
          }

          .additional_context {
            input {
              width: 100%;
              height: 40px;
              padding: 0rem 1rem;
              border: 1px solid $color-grey-300 !important;
              border-radius: 6px;
              &:focus {
                border: 1px solid $color-primary-600 !important;
              }
              &::placeholder {
                color: $color-grey-500 !important;
              }
            }

            p {
              font-size: 14px;
              line-height: 20px;
              margin-bottom: 16px;
            }
            margin-bottom: 2rem;
          }
        }

        .top {
          @include flex-position(space-between, center);
          gap: 12px;
          flex-wrap: wrap;
          margin-bottom: 19px;

          .left {
            @include flex-position(flex-start, center);
            gap: 1rem;
            button {
              font-size: 12px;
              background: $color-success;
              border: none;
            }
            .regenerate {
              @include flex-position(flex-start, center);
              gap: 6px;
              font-size: 0.91em;
              font-weight: 600;
              color: $color-primary-600;
              cursor: pointer;

              button {
                background: $color-primary-600;
                height: 40px;
                width: 180px;
              }

              svg {
                width: 16px;
                height: 16px;

                path {
                  stroke-width: 2px;
                }
              }
            }
          }

          .date {
            @include flex-position(flex-start, center);
            gap: 4px;
            margin: 0 0 0 auto;
            padding: 9px;
            font-size: 0.8em;
            font-weight: 500;
            color: $color-white;
            background: $color-primary-600;
            border-radius: 12px;
            padding: 13px 20px;
            cursor: pointer;
            transition: 0.2s ease;
            svg {
              margin-top: -1px;
            }
            svg path {
              stroke: $color-white;
            }

            &:hover {
              transform: scale(1.03);
            }
          }
        }

        .body {
          padding: 16px;
          padding-bottom: 40px;
          background: $color-grey-50;
          border-radius: 12px;

          @media (max-width: 1230px) {
            padding-left: 16px;
            padding-right: 16px;
          }

          @media (max-width: 1050px) {
            padding-left: 16px;
            padding-right: 16px;
          }

          .actions {
            @include flex-position(flex-end, center);
            gap: 12px;
            margin-bottom: -6px;

            .icon {
              @include flex-position(center, center);
              cursor: pointer;
            }

            svg {
              width: 24px;
              height: 24px;

              path {
                stroke: $color-grey-500;
              }
            }
          }

          .body_content {
            margin-top: 12px;
            // padding: 0px 72px;
            padding: 0px;

            @media (max-width: 1400px) {
              padding: 0px 24px;
            }

            @media (max-width: 1230px) {
              padding: 0px;
            }

            @media (max-width: 1050px) {
              padding: 0px 24px;
            }

            @include xsmdScreen {
              padding: 0px;
            }

            .subject {
              margin-bottom: 24px;
              padding: 0 24px;
              font-size: 1em;
              font-weight: 700;
              line-height: 130%;
              color: $color-grey-500;

              @include xsmdScreen {
                padding: 0 16px;
              }
            }

            .flex_input {
              background: $color-white;
            }

            .message {
              padding: 24px;
              padding-bottom: 30px;
              background: $color-white;
              border-radius: 12px;

              @include smScreen {
                padding: 24px 16px;
              }

              .message_text {
                font-size: 0.875em;
                font-weight: 400;
                line-height: 150%;
                color: $color-grey-500;

                p,
                a,
                span,
                ul,
                ol,
                li {
                  margin-block-start: 1em;
                  margin-block-end: 1em;
                  margin-inline-start: 0px;
                  margin-inline-end: 0px;
                  unicode-bidi: isolate;
                  font-family: inherit !important;
                }

                &.draft {
                  p:not(:nth-of-type(1)):not(:nth-of-type(2)) {
                    position: relative;
                    &::after {
                      content: "Edit";
                      position: absolute;
                      top: -20px;
                      left: 40%;
                      padding: 5px 10px;
                      background-color: $color-sky;
                      color: #fff;
                      border-radius: 5px;
                      opacity: 0;
                      visibility: hidden;
                      transition: opacity 0.2s ease-in-out;
                    }
                    &:hover::after {
                      opacity: 1;
                      visibility: visible;
                    }
                    &:hover {
                      background: $color-grey-150;
                      cursor: pointer;
                    }
                  }
                }

                a {
                  font-weight: 600;
                  color: $color-primary-600;
                }

                ol,
                ul {
                  display: block;
                  list-style-type: decimal;
                  padding-inline-start: 18px;
                  unicode-bidi: isolate;

                  li {
                    margin: 0;
                    margin-left: 15px;
                    list-style: auto;
                  }
                }
              }

              .link {
                margin-top: 23px;
                padding: 12px;
                padding-bottom: 13px;
                border-radius: 16px;
                border: 1px solid $color-grey-100;
                box-shadow: 0px 8px 25px 0px rgba(100, 116, 139, 0.05);

                .title {
                  margin-bottom: 4px;
                  font-size: 0.875em;
                  font-weight: 600;
                  color: $color-grey-900;
                }

                .url {
                  font-size: 0.875em;
                  font-weight: 400;
                  color: $color-grey-500;
                }
              }
            }

            .actions {
              margin: 16px 0 0 auto;
              justify-content: space-between;

              .btn {
                padding: 8px 18px;
                padding-bottom: 9px;
                font-weight: 600;
              }
            }
          }
        }
      }

      .footer {
        .top {
          @include flex-position(space-between, center);
          gap: 16px;
          flex-wrap: wrap;
          margin-top: 16px;

          .schedule {
            @include flex-position(flex-start, center);
            gap: 6px;
            font-size: 0.91em;
            font-weight: 600;
            background: $color-grey-500;
            color: $color-white;
            border-radius: 12px;
            padding: 13px 20px;
            cursor: pointer;

            svg {
              width: 18px;
              height: 18px;

              path {
                stroke-width: 2px;
                stroke: $color-white;
              }
            }
          }
          .campaign_active_tag {
            @include flex-position(flex-start, center);
            gap: 6px;
            padding: 8px;
            font-size: 0.91em;
            font-weight: 600;
            color: $color-primary-600;
            background: $color-grey-150;
            border-radius: 8px;

            svg {
              width: 18px;
              height: 18px;

              path {
                stroke-width: 2px;
              }
            }
          }
          .paused {
            color: $color-amber;
            background: $color-amber-fade;
          }

          .side {
            @include flex-position(flex-end, center);
            gap: 16px;
            margin: 0 0 0 auto;

            .icon {
              cursor: pointer;
            }

            button {
              color: $color-white;
              border: none;
              font-size: 14px;
              // width: 150px;
              height: 48px;
            }

            .klaviyo_preview {
              background: $color-grey-900;
              color: $color-white;
            }

            .active_campaign_btn {
              @include flex-position(flex-end, center);
              gap: 0.5rem;

              .pause {
                background: $color-amber;
              }

              .continue {
                background: $color-success;
              }

              .end {
                background: $color-grey-900;
              }
            }

            .dropdown_container {
              width: auto;

              .dropdown-menu {
                top: auto !important;
                bottom: 24px !important;
              }
            }

            .btn_primary {
              margin-left: 12px;
              font-size: 0.91em;

              .loader_img_container {
                margin: 0;

                .loader_img {
                  width: 25px;
                }
              }
            }
          }
        }

        .bottom_text {
          margin-top: 2rem;
          ul {
            margin-left: 1rem;
            li {
              color: $color-grey-500;
              font-size: 14px;
              line-height: 22.4px;
              list-style-type: disc;
              margin-bottom: 10px;

              span {
                color: $color-success;
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }
        }
      }

      .sun-editor {
        position: relative;
        border: 1px solid $color-grey-100;
        border-radius: 16px;
        z-index: 0 !important;

        input {
          display: none;
        }

        .se-toolbar {
          padding: 3px 12px;
          padding-bottom: 7px;
          outline: none;
          background: $color-grey-100;
          border-radius: 10px 10px 0px 0px;
        }

        .se-btn {
          align-items: center;
        }

        .se-btn-tray {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          row-gap: 6px;
          column-gap: 2px;
          padding-top: 5px;
        }
        .se-btn-select,
        .se-btn-tool-size {
          width: auto;
          max-width: 70px;

          .txt {
            font-size: 14px;
            font-weight: 500;
          }
          svg {
            margin-left: 5px;
          }
        }

        .se-wrapper {
          height: 100%;

          .se-wrapper-inner {
            padding: 16px 12px;
            height: 280px !important;
            font-family: "Trebuchet MS" !important;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            border-top: 1px solid $color-grey-200;

            &.sun-editor-editable {
              color: $color-grey-900;

              p,
              a,
              span,
              ul,
              ol,
              li {
                margin-block-start: 1em;
                margin-block-end: 1em;
                margin-inline-start: 0px;
                margin-inline-end: 0px;
                unicode-bidi: isolate;
                font-family: inherit !important;
              }

              a {
                font-weight: 600;
              }

              ol,
              ul {
                display: block;
                list-style-type: decimal;
                padding-inline-start: 18px;
                unicode-bidi: isolate;

                li {
                  margin: 0;
                  margin-left: 15px;
                  list-style: auto;
                }
              }
            }
          }
        }

        .se-resizing-bar {
          display: none;
        }

        .se-dialog {
          input {
            display: inherit;

            &:focus {
              border: 1px solid $color-primary-600 !important;
            }
          }
        }
      }
    }
  }
}
