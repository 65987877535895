.no_data_container {
  @include flex-position(flex-start, center);
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 1.5rem;
  margin-top: 44px;
  p {
    color: $color-grey-500;
    font-size: 20px;
  }
}
