.insights_page_container {
  width: 100%;

  .top_filters {
    margin-bottom: -44px !important;
  }

  .section_header_container {
    margin-top: 36px;
    margin-bottom: 16px;

    &:first-child {
      margin-top: 0;
    }

    .status {
      text-transform: capitalize;
      font-weight: 700;

      span {
        margin-left: 5px;
        font-weight: 400;
        color: $color-grey-500;
      }
    }

    .amber {
      color: $color-amber !important;
    }

    .primary {
      color: $color-primary-600;
    }
    .success {
      color: $color-success;
    }
    .error {
      color: $color-error;
    }

    svg {
      width: 24px;
      height: 24px;

      path {
        stroke: $color-grey-400;
      }
    }
  }

  .layout {
    .tag {
      @include flex-position(flex-start, center);
      gap: 6px;
      width: auto;

      .dot {
        width: 6px;
        height: 6px;
        border-radius: 50%;
      }

      p {
        margin-top: -0.5px;
        font-size: 0.8em;
        font-weight: 500;
        width: max-content;
      }
    }

    .title {
      font-size: 1em;
      font-weight: 600;
      color: $color-grey-900;
      width: 100%;
      cursor: pointer;
    }

    .desc {
      @include ellipsisHeight(3);
      font-size: 0.8em;
      line-height: 160%;
      color: $color-grey-500;
      width: 100%;
    }

    .date {
      @include flex-position(flex-start, center);
      gap: 4px;
      padding: 8px;
      font-size: 0.8em;
      font-weight: 500;
      color: $color-grey-500;
      background: $color-grey-50;
      border-radius: 8px;

      svg {
        margin-top: -1px;
      }
    }

    .customers {
      @include flex-position(flex-end, center);

      img {
        margin-left: -5px;
        width: 30px;
        height: auto;
        border: 1.5px solid $color-white;
        border-radius: 50%;

        &:first-child {
          margin-left: 0px;
        }
      }
    }

    .dropdown-menu {
      top: 0px !important;
      inset: auto !important;
    }
  }

  .list_layout {
    min-width: 1035px;

    .item {
      @include grid-columns(1fr 2fr 1fr 0.15fr, 24px);
      position: relative;
      margin-bottom: 16px;
      padding: 18px 16px;
      background: $color-white;
      border-radius: 12px;
      z-index: 1;
      cursor: pointer;

      &:hover {
        background: $color-grey-200;
      }

      &:last-child {
        margin: 0;
      }

      .title_and_tag {
        @include flex-position(center, flex-start);
        flex-direction: column;

        .title {
          margin-bottom: 6px;
        }
      }

      .date_and_customers {
        @include flex-position(center, center);
        gap: 12px;
      }

      .insight_actions {
        @include flex-position(center, center);
        position: relative;
        z-index: 12;
        width: 100%;
        height: 100%;
      }
    }
  }

  .grid_layout {
    @include grid-columns(repeat(3, 1fr), 24px);

    @media (max-width: 1242px) {
      @include grid-columns(repeat(2, 1fr), 24px);
    }

    @include xsmdScreen {
      @include grid-columns(repeat(1, 1fr), 24px);
    }

    .item {
      @include flex-position(flex-start, flex-start);
      flex-direction: column;
      padding: 18px 16px;
      background: $color-white;
      border-radius: 12px;
      cursor: pointer;

      &:hover {
        background: $color-grey-200;
      }

      .top {
        @include flex-position(space-between, center);
        margin-bottom: 6px;
        width: 100%;
      }

      .title {
        margin-bottom: 17px;
      }

      .footer {
        @include flex-position(space-between, center);
        margin-top: auto;
        padding-top: 18px;
        width: 100%;
      }
    }
  }
}

.insights_info_page_container {
  width: 100%;

  .section_header_container {
    margin-bottom: 32px;

    .side {
      &:last-child {
        padding-right: 32px;

        @include xsmdScreen {
          padding-right: 0;
        }
      }

      .title {
        font-size: 1.1em;
      }
    }
  }

  .content {
    width: 100%;

    .left_side {
      width: 100%;

      @media (max-width: 1335px) {
        @include flex-position(flex-start, normal);
        flex-wrap: wrap;
        gap: 24px;
      }

      .info {
        margin-bottom: 28px;
        padding: 18px 16px;
        background: $color-white;
        border-radius: 12px;

        &:last-child {
          margin: 0;
        }

        @media (max-width: 1335px) {
          margin: 0;
          flex-grow: 1;
        }

        .title {
          margin-bottom: 14px;
          font-size: 1.05em;
          font-weight: 700;
          color: $color-grey-900;
        }

        ul {
          padding-left: 22px;

          li {
            font-size: 0.8em;
            line-height: 160%;
            color: $color-grey-500;
            list-style: disc;
          }
        }
      }

      .campaigns {
        .list {
          width: 100%;

          .item {
            @include flex-position(flex-start, center);
            gap: 14px;
            padding: 18px 14px;
            width: 100%;
            background: $color-grey-50;
            border-radius: 12px;

            &:nth-child(even) {
              background: $color-white;
            }

            .form_group_container {
              margin: 0;
            }

            .icon {
              @include flex-position(center, center);
              cursor: pointer;

              svg {
                width: 22px;
                height: 22px;
              }
            }

            .title {
              @include ellipsisHeight(1);
              margin: 0;
              font-size: 0.95em;
              width: 100%;
              cursor: pointer;
            }
          }
        }
      }

      .insights {
        ul {
          li {
            margin-bottom: 6px;
            font-size: 0.95em;
            font-weight: 600;
            color: $color-grey-900;

            &:last-child {
              margin: 0;
            }
          }
        }
      }
    }

    .right_side {
      padding: 24px 24px;
      padding-bottom: 48px;
      width: 100%;
      background: #ffffff;
      border-radius: 12px;

      @media (max-width: 1335px) {
        margin-top: 24px;
      }

      @include mdScreen {
        padding: 18px 16px;
        padding-bottom: 20px;
      }
    }
  }
}

.insight_details_container {
  .desc_title {
    @include flex-position(space-between, center);
    gap: 18px;
    margin-bottom: 18px;

    @include xsmdScreen {
      flex-direction: column;
      gap: 14px;
    }

    p {
      @include ellipsisHeight(2);
      font-size: 1em;
      font-weight: 500;
      color: $color-grey-900;
      line-height: 140%;
    }

    .form_group_container {
      padding: 8px 10px;
      font-size: 0.9em;
      background: $color-amber;
      border: 1px solid $color-amber !important;
      border-radius: 12px;

      @include xsmdScreen {
        align-self: flex-start;
      }

      .form-group {
        font-size: 1.05em;
        width: max-content;

        select {
          color: $color-white;

          option {
            color: $color-grey-400;
          }
        }

        .select_container__control {
          padding: 0;
          background: none !important;
          border: none !important;

          .select_container__input {
            color: $color-white !important;
          }

          svg path {
            fill: $color-white !important;
          }

          .select_container__single-value {
            color: $color-white !important;
          }
        }
      }
    }
  }

  .accordion_content {
    .header {
      @include grid-columns(1fr 1.5fr 1fr 1fr, 16px);
      width: 100%;

      @include lgScreen {
        @include grid-columns(1fr 1.5fr, 24px);
      }

      @media (max-width: 480px) {
        @include grid-columns(1fr, 24px);
      }

      .info {
        .title {
          margin-bottom: 12px;
          font-size: 0.8em;
          font-weight: 500;
          color: $color-grey-400;
        }

        .customers {
          @include flex-position(flex-start, center);

          img {
            margin-left: -5px;
            width: 32px;
            height: 32px;
            border: 1.5px solid $color-white;
            border-radius: 50%;
            object-fit: contain;

            &:first-child {
              margin-left: 0px;
            }
          }

          p {
            @include flex-position(center, center);
            margin-left: -5px;
            width: 32px;
            height: 32px;
            font-size: 0.7em;
            font-weight: 600;
            color: $color-grey-900;
            background: $color-grey-200;
            border: 1.5px solid $color-white;
            border-radius: 50%;
          }
        }

        &.segments {
          ul {
            padding-left: 16px;

            li {
              margin-bottom: 6px;
              font-size: 0.85em;
              font-weight: 500;
              color: $color-grey-500;
              line-height: 130%;
              list-style: disc;

              &:last-child {
                margin: 0;
              }
            }
          }

          .btn {
            margin-top: 6px;
            font-size: 0.85em;
            font-weight: 500;
          }
        }

        .date {
          @include flex-position(flex-start, center);
          gap: 4px;
          padding: 9px 8px;
          font-size: 0.8em;
          font-weight: 600;
          width: fit-content;
          color: $color-grey-900;
          background: $color-grey-50;
          border-radius: 8px;

          svg {
            margin-top: -1px;

            path {
              stroke: $color-grey-900;
            }
          }
        }
      }
    }

    .description {
      margin-top: 40px;

      .title {
        margin-bottom: 7px;
        font-size: 0.95em;
        font-weight: 600;
        color: $color-grey-900;
      }

      .desc {
        font-size: 0.85em;
        color: $color-grey-500;
        line-height: 150%;
      }

      .btn {
        margin-top: 26px;
        font-size: 0.85em;
        font-weight: 600;
      }
    }

    .rejection_reason {
      margin-top: 36px;
      font-size: 12px;
      font-weight: 500;
      line-height: 19.2px;
      color: $color-error;
      span {
        font-size: 14px;
        font-weight: 400;
        color: $color-grey-500;
      }
    }

    .btn_actions {
      @include flex-position(flex-start, center);
      gap: 1.5rem;
      margin-top: 36px;

      button {
        font-size: 14px;
      }

      .reject {
        background: $color-error;
        color: $color-white;
        border: none;
      }
      .complete {
        background: $color-success;
        color: $color-white;
        border: none;
      }
    }

    .actions {
      margin-top: 36px;

      .section_header_container {
        margin-bottom: 24px;

        .side {
          padding: 0;

          .title {
            font-size: 0.95em;
            font-weight: 600;
            color: $color-grey-900;
          }

          .flex {
            @include flex-position(flex-end, center);

            svg {
              margin-right: 6px;
              width: 16px;
              height: 16px;
            }

            p {
              font-size: 0.8em;
              font-weight: 700;
              color: $color-primary-600;
            }
          }
        }
      }

      .item {
        @include flex-position(space-between, center);
        gap: 24px;
        margin-bottom: 10px;

        @include mdScreen {
          gap: 16px;
        }

        &:last-child {
          margin: 0;
        }

        .left {
          @include flex-position(flex-start, flex-start);
          gap: 6px;

          p {
            font-size: 0.875em;
            font-weight: 500;
            color: $color-grey-900;
            line-height: 150%;
          }
        }

        .right {
          @include flex-position(flex-end, center);
          gap: 16px;

          .icon {
            @include flex-position(center, center);
            cursor: pointer;
          }

          svg path {
            stroke: $color-grey-500;
          }
        }
      }
    }
  }
}
