@import "../abstract/mixins.scss";
@import "../abstract/variables.scss";

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;

  &:before,
  &:after {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  }
}

body {
  font-family: $font-family;
  font-size: 1em;
  font-weight: normal;
  background: $color-white;
}

#root {
  background: $color-grey-50;
}

.section {
  padding: 0px 15px;

  @media screen and (max-width: 380px) {
    padding: 0px 12px;
  }
}

div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
button,
span,
ul,
li,
label,
input,
textarea,
select {
  margin: 0;
  font-family: $font-family !important;
  color: $color-grey-900;
  letter-spacing: 0.2px;
}

ul {
  li {
    list-style: none;
    padding: 0;
  }
}

a {
  text-decoration: none;
  color: inherit;

  &:active,
  &:hover,
  &:focus {
    @extend a;
  }
}

input,
select,
button,
.btn {
  &:focus,
  &:active {
    box-shadow: none !important;
    outline: 0 !important;
  }
}

input:focus,
select:focus {
  border: 1px solid $color-white !important;
}

input::-webkit-input-placeholder {
  color: $color-grey-200 !important;
}

input:-ms-input-placeholder {
  color: $color-grey-200 !important;
}

input::placeholder {
  color: $color-grey-200 !important;
}

input[readonly] {
  background-color: rgba(0, 25, 132, 0.05);
}

.form-group {
  margin-bottom: 0;
}

.btn {
  @include flex-position(center, center);
  gap: 8px;
  padding: 13px 20px;
  padding-bottom: 14px;
  border-radius: 12px;
  font-weight: 700;
  cursor: pointer;
  // width: 100%;

  .icon {
    width: 1.2em;
  }

  .loader_img {
    margin-top: 0 !important;
  }
}

.btn_primary {
  @extend .btn;
  color: $color-white;
  background: $color-primary-600;
  border: 1px solid $color-primary-600;

  &:hover,
  &:focus {
    @extend .btn_primary;
  }

  path {
    stroke: $color-white;
    stroke-width: 2;
  }
}

.btn_secondary {
  @extend .btn;
  color: $color-primary-600;
  background: $color-white;
  border: 1px solid $color-grey-200;

  p {
    color: $color-primary-600;
  }

  &:hover,
  &:focus {
    @extend .btn_secondary;
  }

  path {
    stroke: $color-primary-600;
    stroke-width: 2;
  }
}

.btn_tertiary {
  @extend .btn;
  color: $color-grey-900;
  background: $color-white;
  border: 1px solid $color-grey-200;

  p {
    color: $color-grey-900;
  }

  &:hover,
  &:focus {
    @extend .btn_tertiary;
  }

  path {
    stroke: $color-grey-900;
    stroke-width: 2;
  }
}

.btn_tertiary_primary {
  @extend .btn;
  color: $color-grey-900;
  background: $color-white;
  border: 1px solid $color-primary-600;

  p {
    color: $color-grey-900;
  }

  &:hover,
  &:focus {
    @extend .btn_tertiary_primary;
  }

  path {
    stroke: $color-grey-900;
    stroke-width: 2;
  }
}

.btn_text {
  @extend .btn;
  gap: 4px;
  padding: 0;
  color: $color-primary-600;
  background: none;
  border: none;

  p {
    color: $color-primary-600;
  }

  &:hover,
  &:focus {
    @extend .btn_text;
  }

  .left_icon {
    margin: 0 !important;

    path {
      stroke: $color-primary-600;
      stroke-width: 2;
    }
  }
}

.dashboard {
  @include flex-position(flex-start, flex-start);
  max-width: 1800px;
  width: 100%;
  min-height: 100vh;

  &.collapse_sidebar {
    .dashboard_content {
      width: calc(100% - 60px);
    }
  }

  &.hide_sidebar {
    .sidebar_container {
      display: none;
    }

    .dashboard_content {
      width: 100%;
    }
  }

  .dashboard_content {
    position: relative;
    margin: 0 0 0 auto;
    width: calc(100% - 250px);
    min-height: 100vh;
    background: $color-grey-50;

    @media screen and (max-width: 850px) {
      width: 100%;
    }

    .page_container {
      padding: 32px 32px 60px 32px;

      @include lgScreen {
        padding: 32px 24px 60px 24px;
      }

      @media screen and (max-width: 850px) {
        width: 100%;
      }

      @include xsmdScreen {
        padding: 32px 12px 60px 12px;
      }

      .top_filters {
        @include flex-position(flex-end, center);
        gap: 16px;
        margin-top: -14px;
        margin-bottom: 28px;

        .select_container__control {
          padding: 9px 12px;
        }
      }
    }
  }

  .loader_img_container {
    margin: 100px auto;

    .loader_img {
      width: 35px;
    }
  }

  .capitalize {
    text-transform: capitalize;
  }

  .go_back_container {
    @include flex-position(flex-start, center);
    width: fit-content;
    cursor: pointer;

    svg {
      margin-right: 8px;
      width: 18px;
      height: 18px;
    }

    p {
      font-weight: 500;
    }
  }
}

.vertical_scroll {
  overflow-y: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    width: 0px;
    height: 0px;
  }
}

.gs-utl-z-20 {
  z-index: 10000 !important;
}
