.preview-header {
    display: flex;
    gap: 25px;
    align-items: center;
}

.preview-template_body {
    padding: 30px;
    background-color: $color-grey-200;
}

.highlight-text {
    font-size: 12px;
    font-weight: 400;
    color: #0F172A;
    line-height: 20px;
}

.template-tag-text {
    font-size: 14px;
    font-weight: 400;
    color: #0F172A;
    margin: 20px 0 10px 0;
}

.template-tags-container {
    display: flex;
    gap: 14px;
    margin-top: 15px;
}

.tags-container {
    background-color: #d5dde4;
    padding: 8px;
    border-radius: 6px;
}

.preview-tags-text {
    color: $color-grey-400;
    font-size: 12px;
}



.preview-modal {
    width: 90vw !important;
    max-width: 90vw;
    height: 90vh !important;
    max-height: 100vh;
}

.preview-template_body {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
}

.template-iframe {
    flex-grow: 1;
    width: 100%;
    height: calc(100% - 150px);
    border: none;
}

.confirm_modal,.regenerate_campaign_modal {
    display: block;
    background: white;
    border: 1px solid #ddd;
    border-radius: 20px !important;
    padding: 10px !important;
    width: 600px;
    max-width: 1000px;
    margin: 200px auto;
}

.dialog_buttons {
    display: flex;
    width: 200px;
    margin-top: 40px;
    gap: 20px;
}

.dialog_body {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    align-items: center;
    justify-content: center;

    p {
        text-align: center;
    }
    .regenerate-text{
        text-align: left;
    }
}



.regenerate_action{
    @include flex-position(flex-start, flex-start);
    gap: 1rem;
    margin-top: 2.5rem;
    width: 100%;
    input{
     border: 1px solid $color-grey-200;
     border-radius: 5px;
     height: 40px;
     width: 100%;
     padding: 0 10px;
     outline: 1px solid $color-grey-200;
     &::placeholder{
        color: $color-grey-400;
        font-size: 12px;
     }
     &:focus{
        border: 1px solid $color-grey-200;
     }
    }
    button{
      background: $color-grey-500;
      border: none;
      color: $color-white;
      height: 40px;
      width: 80px;
      font-size: 12px;
      border-radius: 5px;
    }
}

.cancel_regenerate_button{
    margin-top: 2rem;
    @include flex-position(center, center);
}


.generated-campaign_modal,
.preview_campaign-modal {
    display: block;
    background: white;
    padding: 10px !important;
    margin: 10px auto;
    width: 800px;
}


.generated_campaign_heading {
    margin-top: 30px;

    h4 {
        font-size: 16px;
        font-weight: 500;
        color: #0F172A;
    }

    p {
        font-size: 16px;
        font-weight: 400;
        color: #2563EB;
    }
}

.recipient_sender-container {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .gen_sender {
        font-size: 14px;
        margin-bottom: 8px;
        color: #0F172A;
        font-weight: 400;
    }

    .gen_sender-orange {
        color: #F6A723;
        font-size: 14px;
    }

    .number_of-customers {
        color: #2563EB;
        font-size: 14px;
    }
}

.gen_email-header {
    font-size: 14px;
    margin-top: 20px;
    color: #0F172A;
    font-weight: 400;
}

.gen_email-body {
    color: #64748B;
    font-size: 14px;
    margin-top: 15px;
    font-style: italic;
    line-height: 20px;
}

.custom-dropdown_container {
    margin: 40px 0;
    width: 200px;
}

.icon_send {
    color: #2563EB;
}

.gen_dialog-footer {
    margin: 20px 0 60px 0px;

    .gen-footer_one {
        margin-bottom: 40px;
        font-size: 12px;
        font-weight: 500;
    }

    .gen-footer_two {
        margin-bottom: 20px;
        font-size: 14px;
        font-weight: 300;
        color: #344054;
    }
}

.generated_button-container {
    display: flex;
    gap: 20px;
    padding: 0 20px;
    margin-bottom: 20px;

}