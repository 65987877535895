.button_component {
  &.loading {
    padding: 9px 30px !important;
    padding-bottom: 10px !important;

    .loader {
      @include flex-position(center, center);

      p {
        margin-left: 8px;
        font-style: italic;
        color: $color-white;
      }
    }
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  font-size: 0.95em;

  .left_icon {
    @include flex-position(center, center);
    margin-right: 3px;
  }

  .right_icon {
    @include flex-position(center, center);
    margin-left: 3px;
  }

  svg {
    width: 16px;
    height: 16px;
  }
}

.button_box {
  margin-top: 20px;
  padding: 20px;
}