.datepicker_container {
  @include flex-position(flex-start, center);
  position: relative;
  padding: 13px 16px 12px 16px;
  width: fit-content;
  height: auto;
  border: 1px solid $color-grey-200;
  border-radius: 12px;
  background: $color-white;

  .text {
    position: absolute;
    top: 13px;
    left: 13px;
  }

  &:focus-within {
    border: 1px solid $color-primary-600;
  }

  svg {
    margin-top: -2px;
    margin-right: 8px;
    width: 20px;
    height: 20px;
  }

  .react-datepicker-wrapper {
    margin-top: -2px;
    width: inherit;

    .date_picker_input {
      font-size: 0.95em;
      letter-spacing: normal;
      color: $color-grey-900;
      height: auto;
      width: 100%;
      background: transparent;
      border: none;

      &:focus {
        border: none !important;
      }
    }
  }
}
