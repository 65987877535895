.table_container {
  padding: 16px;
  padding-bottom: 20px;
  width: 100%;
  background: #ffffff;
  border-radius: 12px;

  .table-responsive {
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      height: 8px;
      background: $color-grey-50;
    }
    &::-webkit-scrollbar-thumb {
      height: 0px;
      background: $color-grey-400;
      border-radius: 4px;
    }

    .table {
      margin: 0;
      width: 100%;
      border-collapse: collapse;

      thead tr th {
        padding: 16px 16px;
        font-weight: 500;
        font-size: 0.85em;
        text-align: left;
        color: $color-grey-400;
        background: $color-grey-50;
        border: none;

        &:first-child {
          border-radius: 8px 0px 0px 8px;
        }

        &:last-child {
          text-align: right;
          border-radius: 0px 8px 8px 0px;
        }
      }

      tbody {
        border: none;

        tr {
          background: $color-white;
          border-bottom: 0.5px solid $color-grey-100;

          td {
            padding: 18px 16px;
            font-weight: 600;
            font-size: 0.91em;
            color: $color-grey-900;
            border: none;

            &:last-child {
              text-align: right;
            }

            &.loading_text {
              text-align: center;
              font-size: 0.95em;
              font-weight: 500;
            }

            .text_wrap {
              margin: 0;
              max-width: 400px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              @media screen and (max-width: 1080px) {
                max-width: 180px;
              }
            }

            .capitalize {
              text-transform: capitalize;
            }

            a {
              color: $color-primary-600;
              font-weight: bold;
              text-decoration: underline;
              cursor: pointer;
            }

            .link {
              @extend a;
            }

            .dropdown_container {
              .drop svg path {
                stroke: $color-grey-900;
              }

              .dropdown-menu {
                top: auto !important;

                .dropdown-item {
                  font-size: 0.95em;
                }
              }
            }
          }
        }
      }

      .form_group_container {
        input {
          width: 18px;
          height: 18px;
        }

        label {
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}
