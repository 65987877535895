.section_header_container {
  @include flex-position(space-between, center);
  gap: 16px;

  .side {
    @include flex-position(flex-start, center);
    gap: 18px;

    &:last-child {
      gap: 24px;
    }

    .icon {
      @include flex-position(center, center);
      cursor: pointer;
    }

    .title {
      margin-top: -1px;
      color: $color-grey-900;
      font-size: 1em;
      font-weight: 700;
    }
  }
}
