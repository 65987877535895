$font-family: "Inter", sans-serif;

$color-primary-50: #eff6ff;
$color-primary-600: #2563eb;
$color-primary-500: #5687f1;


$color-sky: #38bdf8;

$color-white: #ffffff;

$color-grey-50: #f8fafc;
$color-grey-100: #f1f5f9;
$color-grey-150: #eff6ff;
$color-grey-200: #e2e8f0;
$color-grey-300: #cbd5e1;
$color-grey-400: #94a3b8;
$color-grey-500: #64748b;
$color-grey-900: #0f172a;

$color-tailgrids: #637381;
$color-amber: #f6a723;
$color-amber-fade: #fffbeb;
$color-algal: #24d164;

$color-pink-50: #fdf2f8;
$color-pink-500: #ed4f9d;

$color-success: #26ce6a;
$color-success-trans: rgba(38, 206, 106, 0.15);
$color-error: #fb190b;
$color-error-trans: rgba(251, 25, 11, 0.15);

$box-shadow: 0px 4px 40px 0px rgba(24, 29, 37, 0.05);
$box-shadow-2: 4px 4px 28.8px 0px #1c274c33;