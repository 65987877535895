.brand_insights_page_container {
  width: 100%;
  padding: 1.5rem 3rem;
  .logo_container {
    @include flex-position(flex-start, center);
    margin-bottom: 2rem;
  }
  .header {
    @include flex-position(space-between, center);
    .bread_crumbs {
      @include flex-position(flex-start, center);
      gap: 1rem;

      p {
        font-weight: 500;
        font-size: 14px;
        color: $color-grey-500;

        &.store_data {
          font-weight: 600;
          color: $color-grey-900;
        }
      }
    }
  }
  .body_content {
    margin-top: 2rem;
    .title {
      font-weight: 700;
      font-size: 24px;
      color: $color-grey-900;
      margin-bottom: 1rem;
    }
    p {
      font-size: 16px;
      font-weight: 300;
      margin-bottom: 2rem;
    }
    .brand_insight_details {
      width: 100%;
      .brand_section {
        @include flex-position(flex-start, flex-start);
        gap: 1.2rem;
        border: 1px solid $color-grey-200;
        border-radius: 12px;
        padding: 1.5rem;
        margin-bottom: 2rem;
        background: $color-white;

        .number {
          font-size: 24px;
          font-weight: 700;
          margin-bottom: 0;
          line-height: 16px;
        }

        .right {
          width: 100%;
          .section_title {
            font-weight: 300;
            font-size: 16px;

            span {
              font-size: 14px;
              font-weight: 600;
            }
          }

          .brand_tags {
            @include flex-position(flex-start, center);
            flex-wrap: wrap;
            gap: 0.8rem;
            border: 1px solid $color-grey-200;
            border-radius: 12px;
            padding: 1.5rem;

            &.no-border {
              border: none;
              padding-top: 1rem;
              padding-left: 0;
              .tag {
                cursor: default;
              }
            }

            .tag {
              @include flex-position(flex-start, center);
              display: inline-flex;
              gap: 5px;
              padding: 5px 8px;
              background: #f9f5ff;
              border-radius: 8px;
              border: 1px solid #e9d7fe;
              cursor: pointer;
              svg {
                cursor: pointer;
              }
              &.tag_style_1 {
                background: #f0f9ff;
                border: 1px solid #b9e6fe;
                svg path {
                  stroke: #026aa2 !important;
                }
              }
              &.tag_style_2 {
                background: #ecfdf3;
                border: 1px solid #abefc6;
                svg path {
                  stroke: #067647 !important;
                }
              }
              &.tag_style_3 {
                background: #fffaeb;
                border: 1px solid #fedf89;
                svg path {
                  stroke: #b54708 !important;
                }
              }
              &.tag_style_4 {
                background: #fef3f2;
                border: 1px solid #fecdca;
                svg path {
                  stroke: #b42318 !important;
                }
              }

              .dot {
                height: 6px;
                width: 6px;
                border-radius: 50%;
                background: #9e77ed;
                &.tag_style_1 {
                  background: #026aa2;
                }
                &.tag_style_2 {
                  background: #067647;
                }
                &.tag_style_3 {
                  background: #b54708;
                }
                &.tag_style_4 {
                  background: #b42318;
                }
              }

              p {
                font-size: 12px;
                font-weight: 500;
                color: #9e77ed;
                margin-bottom: 0;
                &.tag_style_1 {
                  color: #026aa2;
                }
                &.tag_style_2 {
                  color: #067647;
                }
                &.tag_style_3 {
                  color: #b54708;
                }
                &.tag_style_4 {
                  color: #b42318;
                }
              }
            }
            .remove_tag {
              @include flex-position(center, center);
            }
            .add_tag {
              @include flex-position(flex-start, center);
              display: inline-flex;
              gap: 5px;
              padding: 5px 8px;
              background: $color-white;
              border-radius: 8px;
              border: 1px solid $color-grey-500;
              cursor: pointer;
              p {
                font-size: 12px;
                font-weight: 500;
                color: $color-grey-500;
                margin-bottom: 0;
              }
            }
          }
          .tag_input {
            padding-top: 1.5rem;
            p {
              font-size: 14px;
              font-weight: 300;
              margin: 0;
            }
            .input_container {
              @include flex-position(flex-start, center);
              margin-top: 0.5rem;
              width: 400px;
              height: 40px;
              border-radius: 5px;
              border: 1px solid $color-grey-300;

              input {
                outline: none;
                background: none;
                border: none;
                height: 100%;
                width: 80%;
                padding: 0 0.5rem;
              }
              .add_btn {
                @include flex-position(center, center);
                gap: 5px;
                padding: 8px;
                border-left: 1px solid $color-grey-300;
                height: 100%;
                width: 20%;
                cursor: pointer;
                p {
                  font-weight: 600;
                }
              }
            }
          }
          .text_area {
            textarea {
              width: 100%;
              border-radius: 8px;
              border: 1px solid $color-grey-300;
              background: $color-grey-100;
              padding: 1rem;
              &::placeholder {
                font-size: 16px;
              }
            }
          }
          .writing_approach {
            width: 320px;
            position: relative;

            .approach_tag {
              padding: 5px 8px;
              background: #f0f9ff;
              border-radius: 8px;
              border: 1px solid #b9e6fe;
              cursor: pointer;
              transition: 0.2s ease;
              &:hover {
                transform: scale(1.1);
              }
              p {
                font-size: 12px;
                color: #026aa2;
                margin-bottom: 0;
              }
            }
            .writing_approach_select {
              @include flex-position(flex-start, center);
              position: relative;
              padding: 0 8px;
              overflow: auto;
              width: 100%;
              height: 40px;
              border: 1px solid $color-grey-200;
              border-radius: 6px;
              cursor: pointer;

              &::after {
                content: "";
                position: absolute;
                background: $color-white;
                width: 25px;
                height: 100%;
                top: 0;
                right: 0;
                z-index: 2;
              }
              p {
                margin-bottom: 0;
                font-size: 12px;
                color: $color-grey-500;
              }
              svg {
                position: absolute;
                top: 11px;
                right: 5px;
                z-index: 3;
              }
            }
            .approach_select_dropdown {
              @include flex-position(flex-start, flex-start);
              flex-direction: column;
              gap: 1rem;
              position: absolute;
              top: 50px;
              left: 0;
              width: 100%;
              border: 1px solid $color-grey-200;
              min-height: 100px;
              border-radius: 6px;
              padding: 1.5rem;
              background: $color-white;
              z-index: 5;
            }
          }
          .file_upload {
            @include flex-position(flex-start, center);
            flex-direction: column;
            gap: 1rem;
            padding-top: 1rem;
            .upload {
              @include flex-position(flex-start, center);
              flex-direction: column;
              gap: 10px;
              width: 100%;
              border: 1px solid $color-grey-200;
              border-radius: 12px;
              padding: 1rem;
              input {
                display: none;
              }
              .upload_icon {
                @include flex-position(center, center);
                flex-direction: column;
                width: 40px;
                height: 40px;
                border-radius: 10px;
                border: 1px solid $color-grey-200;
                cursor: pointer;
                img {
                  align-self: center;
                }
              }
              p {
                margin: 0;
                font-size: 14px;
                span {
                  color: $color-primary-600;
                }
              }
            }
            .uploaded_files {
              @include flex-position(flex-start, center);
              gap: 0.8rem;
              width: 100%;
              border: 1px solid $color-grey-200;
              border-radius: 12px;
              padding: 1rem;
              background: $color-white;
              .file {
                @include flex-position(flex-start, flex-start);
                flex-direction: column;
                gap: 5px;
                p {
                  margin: 0;
                  font-size: 14px;
                }
                p:first-child {
                  font-weight: 500;
                }
              }
            }
          }
        }
      }
      .btn {
        button {
          width: 404px;
          height: 56px;
        }
      }
    }
  }
}
