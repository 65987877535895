.onboarding_page_container {
  padding-bottom: 70px !important;

  .logo_container img {
    width: 200px;
  }

  .section {
    @include flex-position(center, center);
    flex-direction: column;
    margin: 0 auto;
    margin-top: 70px;
    padding: 0 !important;
    max-width: 650px;
    width: 100%;

    .title {
      margin-bottom: 24px;
      font-size: 1.3em;
      font-weight: 500;
      color: $color-grey-500;
    }

    .stores {
      width: 100%;

      .item {
        @include flex-position(flex-start, center);
        gap: 18px;
        margin-bottom: 24px;
        width: 100%;

        @include smScreen {
          gap: 12px;
        }

        &:last-child {
          margin: 0;
        }

        img {
          width: 40px;
          height: auto;

          @include smScreen {
            width: 30px;
          }
        }

        .form {
          @include flex-position(flex-start, center);
          width: 100%;
          // border: 1px solid $color-grey-200;
          border-radius: 6px;

          &:focus-within {
            border: 1px solid $color-primary-600;
          }

          .form_group_container {
            margin: 0;
            width: inherit;

            .flex_input {
              border: none !important;
            }

            input::placeholder {
              color: $color-grey-300 !important;
            }
          }

          .btn {
            border-radius: 6px;
            gap: 18px;
            padding: 15px 20px;
            width: 100%;
          }
        }
      }
    }

    .note {
      font-size: 0.9em;
      font-weight: 500;
      color: $color-grey-500;
      font-style: italic;
    }

    .custom_stores {
      @include flex-position(flex-start, flex-start);
      gap: 18px;
      width: 100%;

      @include smScreen {
        gap: 12px;
      }

      img {
        width: 40px;
        height: auto;

        @include smScreen {
          width: 30px;
        }
      }

      .list {
        width: 100%;

        .item {
          margin-bottom: 24px;
          width: 100%;

          &:last-child {
            margin: 0;
          }

          .btn {
            @include flex-position(center, center);
            gap: 18px;
            padding: 15px 20px;
            width: 100%;
          }

          .file_uploader {
            @include flex-position(center, center);
            flex-direction: column;
            margin-top: 12px;
            width: 100%;
            height: 165px;
            border-radius: 6px;
            border: 1px dashed $color-grey-400;
            cursor: pointer;

            svg {
              margin-bottom: 11px;
              width: 51.486px;
              height: 50px;

              path {
                fill: $color-grey-500;
              }
            }

            p {
              font-size: 0.85em;
              color: $color-grey-500;

              span {
                color: $color-primary-600;
              }
            }
          }
        }
      }
    }

    .btn_tertiary_primary {
      margin: 0 0 0 auto;
      margin-top: 56px;
      padding: 10px 16px;
      padding-bottom: 11px;
      opacity: 0.8;
    }
  }

  .generating_data {
    @include flex-position(center, center);
    flex-direction: column;
    margin: 0 auto;
    margin-top: 100px;
    max-width: 650px;
    width: 100%;
    text-align: center;

    .title {
      font-size: 2.1em;
      font-weight: 500;
      text-align: center;
      color: $color-grey-500;

      @include smScreen {
        font-size: 1.6em;
      }
    }

    .progress_bar {
      margin-top: 70px;
      height: 18px;
      width: 100%;
      background: $color-grey-100;
      border-radius: 50px;

      @include smScreen {
        margin-top: 50px;
      }

      .value {
        height: 100%;
        background: $color-primary-600;
        border-radius: 50px;
        transition: 0.4s ease-in-out;
      }
    }

    .please_wait {
      margin-top: 35px;
      font-size: 0.95em;
      font-weight: 500;
      color: $color-grey-500;
    }

    .synching_store {
      margin-top: 35px;
      font-size: 1em;
      font-weight: 500;
      color: $color-grey-500;
      font-style: italic;
      text-align: center;
      line-height: 150%;

      span {
        font-size: 1.5em;
        color: $color-grey-500;
      }

      .loader_img_container {
        margin-top: 30px;
        margin-bottom: 50px;
      }
    }
  }
}

.synch_data_container {
  @include flex-position(flex-start, center);
  flex-direction: column;
  padding: 70px;
  padding-top: 70px;
  width: 60%;
  max-height: 100vh;
  height: 100vh;
  overflow-y: auto;

  @include lgScreen {
    padding: 60px 20px;
  }

  @include mdScreen {
    padding: 40px 20px;
    width: 100%;
    height: 100%;
    min-height: 100%;
  }

  @include xsScreen {
    padding: 70px 12px;
  }

  .generating_data {
    @include flex-position(center, center);
    height: inherit;
    .synching_store {
      text-align: center;
    }
  }
}
