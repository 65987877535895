.segments_page_container {
  width: 100%;

  .layout {
    .sn {
      font-size: 0.875em;
      font-weight: 700;
    }

    .title {
      font-size: 1.1em;
      font-weight: 700;
      color: $color-grey-900;
      width: 100%;
      cursor: pointer;
    }

    p.info {
      @include flex-position(flex-start, center);
      font-size: 0.8em;
      line-height: 160%;
      color: $color-grey-500;
      white-space: pre-wrap;
    }

    ul {
      padding-left: 22px;
      width: 100%;

      li {
        font-size: 0.8em;
        line-height: 160%;
        color: $color-grey-500;
        list-style: disc;
      }
    }

    .date {
      @include flex-position(flex-start, center);
      gap: 4px;
      padding: 8px;
      font-size: 0.8em;
      font-weight: 500;
      color: $color-grey-500;
      background: $color-grey-50;
      border-radius: 8px;

      svg {
        margin-top: -1px;
      }
    }

    .customers {
      @include flex-position(center, center);
      font-size: 0.9em;
      font-weight: 500;
      color: $color-grey-500;
    }
  }

  .list_layout {
    min-width: 1035px;

    .item {
      @include grid-columns(1fr 2fr 1fr 0.15fr, 24px);
      margin-bottom: 16px;
      padding: 18px 16px;
      background: $color-white;
      border-radius: 12px;
      cursor: pointer;

      &:hover {
        background: $color-grey-200;
      }

      &:last-child {
        margin: 0;
      }

      .sn_and_title {
        @include flex-position(center, flex-start);
        flex-direction: column;

        .sn {
          margin-bottom: 12px;
        }
      }

      .date_and_customers {
        @include flex-position(space-between, center);
        gap: 12px;
      }
    }
  }

  .grid_layout {
    @include grid-columns(repeat(3, 1fr), 24px);

    @media (max-width: 1242px) {
      @include grid-columns(repeat(2, 1fr), 24px);
    }

    @include xsmdScreen {
      @include grid-columns(repeat(1, 1fr), 24px);
    }

    .item {
      @include flex-position(flex-start, flex-start);
      flex-direction: column;
      padding: 18px 16px;
      background: $color-white;
      border-radius: 12px;
      cursor: pointer;
      &:hover {
        background: $color-grey-200;
      }
      .top {
        @include flex-position(space-between, center);
        margin-bottom: 17px;
        width: 100%;
      }

      .title {
        margin-bottom: 8px;
      }

      .footer {
        @include flex-position(space-between, center);
        margin-top: auto;
        padding-top: 18px;
        width: 100%;
      }
    }
  }
}

.segments_info_page_container {
  width: 100%;

  .section_header_container {
    margin-bottom: 32px;

    .side {
      &:last-child {
        padding-right: 32px;

        @include xsmdScreen {
          padding-right: 0;
        }
      }

      .title {
        font-size: 1.1em;
      }
    }
  }

  .content {
    @include grid-columns(3.2fr 6.8fr, 24px);
    width: 100%;

    @media (max-width: 1250px) {
      display: block;
    }

    .left_side {
      width: 100%;

      @media (max-width: 1250px) {
        @include flex-position(flex-start, normal);
        flex-wrap: wrap;
        gap: 24px;
      }

      .info {
        margin-bottom: 28px;
        padding: 18px 16px;
        background: $color-white;
        border-radius: 12px;

        &:last-child {
          margin: 0;
        }

        @media (max-width: 1250px) {
          margin: 0;
          flex-grow: 1;
        }

        .title {
          margin-bottom: 14px;
          font-size: 1.05em;
          font-weight: 700;
          color: $color-grey-900;
        }

        .details {
          font-size: 0.85em;
          line-height: 160%;
          color: $color-grey-500;
        }

        ul {
          padding-left: 22px;

          li {
            font-size: 0.8em;
            line-height: 160%;
            color: $color-grey-500;
            list-style: disc;
          }
        }
      }

      .campaigns {
        .list {
          width: 100%;

          .item {
            @include flex-position(flex-start, center);
            gap: 14px;
            padding: 18px 14px;
            width: 100%;
            background: $color-grey-50;
            border-radius: 12px;

            &:nth-child(even) {
              background: $color-white;
            }

            .form_group_container {
              margin: 0;
            }

            .icon {
              @include flex-position(center, center);
              cursor: pointer;

              svg {
                width: 22px;
                height: 22px;
              }
            }

            .title {
              @include ellipsisHeight(1);
              margin: 0;
              font-size: 0.95em;
              width: 100%;
              cursor: pointer;
            }
          }
        }
      }

      .insights {
        ul {
          li {
            margin-bottom: 6px;
            font-size: 0.95em;
            font-weight: 600;
            color: $color-grey-900;

            &:last-child {
              margin: 0;
            }
          }
        }
      }
    }

    .table_container {
      @media (max-width: 1250px) {
        margin-top: 24px;
      }

      @media (max-width: 610px) {
        .table {
          width: 492px;
        }
      }

      .customer {
        @include flex-position(flex-start, center);
        gap: 12px;
        width: fit-content;

        .image {
          padding: 6px;
          padding-bottom: 3px;
          border-radius: 8px;
          background: $color-grey-50;

          img {
            width: 34px;
            height: 34px;
            object-fit: contain;
          }

          .initials {
            @include flex-position(center, center);
            width: 34px;
            height: 34px;
            padding-bottom: 2px;
            font-size: 1.3em;
          }
        }
      }
    }
  }
}
