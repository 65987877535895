.settings_page_container {
  padding-top: 52px !important;

  .content_container {
    @include flex-position(center, flex-start);
    margin-top: 24px;
    padding: 48px 24px;
    min-height: calc(100vh - 240px);
    background: $color-white;
    border-radius: 12px;

    @include smScreen {
      padding-left: 12px;
      padding-right: 12px;
    }

    .loader_img_container {
      margin: 135px auto;

      .loader_img {
        width: 30px;
      }
    }

    .ai_training {
      width: 100%;

      .tab_header {
        @include flex-position(flex-start, center);
        width: 100%;
        gap: 1rem;

        .title {
          cursor: pointer;

          &.active {
            padding: 8px 12px;
            background: $color-grey-150;
            border-radius: 8px;

            p {
              color: $color-primary-600;
            }
          }

          p {
            color: $color-grey-500;
            font-size: 14px;
          }
        }
      }

      .brand_insight_details {
        width: 100%;
        margin-top: 3rem;

        .brand_section {
          @include flex-position(flex-start, flex-start);
          gap: 1.2rem;
          border: 1px solid $color-grey-200;
          border-radius: 12px;
          padding: 1.5rem;
          margin-bottom: 2rem;

          .update_btn {
            @include flex-position(flex-end, flex-start);
            margin-top: 1.5rem;
            width: 100%;

            button {
              width: auto;
              height: 50px;
            }
          }

          .number {
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 0;
            line-height: 16px;
          }

          .right {
            width: 100%;

            .section_title {
              font-weight: 300;
              font-size: 16px;
              margin-bottom: 32px;

              span {
                font-size: 14px;
                font-weight: 600;
              }
            }

            .brand_tags {
              @include flex-position(flex-start, center);
              flex-wrap: wrap;
              gap: 0.8rem;
              border: 1px solid $color-grey-200;
              border-radius: 12px;
              padding: 1.5rem;

              &.no-border {
                border: none;
                padding-top: 1rem;
                padding-left: 0;

                .tag {
                  cursor: default;
                }
              }

              .tag {
                @include flex-position(flex-start, center);
                display: inline-flex;
                gap: 5px;
                padding: 5px 8px;
                background: #f9f5ff;
                border-radius: 8px;
                border: 1px solid #e9d7fe;
                cursor: pointer;

                svg {
                  cursor: pointer;
                }

                &.tag_style_1 {
                  background: #f0f9ff;
                  border: 1px solid #b9e6fe;

                  svg path {
                    stroke: #026aa2 !important;
                  }
                }

                &.tag_style_2 {
                  background: #ecfdf3;
                  border: 1px solid #abefc6;

                  svg path {
                    stroke: #067647 !important;
                  }
                }

                &.tag_style_3 {
                  background: #fffaeb;
                  border: 1px solid #fedf89;

                  svg path {
                    stroke: #b54708 !important;
                  }
                }

                &.tag_style_4 {
                  background: #fef3f2;
                  border: 1px solid #fecdca;

                  svg path {
                    stroke: #b42318 !important;
                  }
                }

                .dot {
                  height: 6px;
                  width: 6px;
                  border-radius: 50%;
                  background: #9e77ed;

                  &.tag_style_1 {
                    background: #026aa2;
                  }

                  &.tag_style_2 {
                    background: #067647;
                  }

                  &.tag_style_3 {
                    background: #b54708;
                  }

                  &.tag_style_4 {
                    background: #b42318;
                  }
                }

                p {
                  font-size: 12px;
                  font-weight: 500;
                  color: #9e77ed;
                  margin-bottom: 0;

                  &.tag_style_1 {
                    color: #026aa2;
                  }

                  &.tag_style_2 {
                    color: #067647;
                  }

                  &.tag_style_3 {
                    color: #b54708;
                  }

                  &.tag_style_4 {
                    color: #b42318;
                  }
                }
              }

              .remove_tag {
                @include flex-position(center, center);
              }

              .add_tag {
                @include flex-position(flex-start, center);
                display: inline-flex;
                gap: 5px;
                padding: 5px 8px;
                background: $color-white;
                border-radius: 8px;
                border: 1px solid $color-grey-500;
                cursor: pointer;

                p {
                  font-size: 12px;
                  font-weight: 500;
                  color: $color-grey-500;
                  margin-bottom: 0;
                }
              }
            }

            .tag_input {
              padding-top: 1.5rem;

              p {
                font-size: 14px;
                font-weight: 300;
                margin: 0;
              }

              .input_container {
                @include flex-position(flex-start, center);
                margin-top: 0.5rem;
                width: 400px;
                height: 40px;
                border-radius: 5px;
                border: 1px solid $color-grey-300;

                input {
                  outline: none;
                  background: none;
                  border: none;
                  height: 100%;
                  width: 80%;
                  padding: 0 0.5rem;
                }

                .add_btn {
                  @include flex-position(center, center);
                  gap: 5px;
                  padding: 8px;
                  border-left: 1px solid $color-grey-300;
                  height: 100%;
                  width: 20%;
                  cursor: pointer;

                  p {
                    font-weight: 600;
                  }
                }
              }
            }

            .text_area {
              textarea {
                width: 100%;
                border-radius: 8px;
                border: 1px solid $color-grey-300;
                background: $color-grey-100;
                padding: 1rem;

                &::placeholder {
                  font-size: 16px;
                }
              }
            }

            .writing_approach {
              width: 320px;
              position: relative;

              .approach_tag {
                padding: 5px 8px;
                background: #f0f9ff;
                border-radius: 8px;
                border: 1px solid #b9e6fe;
                cursor: pointer;
                transition: 0.2s ease;

                &:hover {
                  transform: scale(1.1);
                }

                p {
                  font-size: 12px;
                  color: #026aa2;
                  margin-bottom: 0;
                }
              }

              .writing_approach_select {
                @include flex-position(flex-start, center);
                position: relative;
                padding: 0 8px;
                overflow: auto;
                width: 100%;
                height: 40px;
                border: 1px solid $color-grey-200;
                border-radius: 6px;
                cursor: pointer;

                &::after {
                  content: "";
                  position: absolute;
                  background: $color-white;
                  width: 25px;
                  height: 100%;
                  top: 0;
                  right: 0;
                  z-index: 2;
                }

                p {
                  margin-bottom: 0;
                  font-size: 12px;
                  color: $color-grey-500;
                }

                svg {
                  position: absolute;
                  top: 11px;
                  right: 5px;
                  z-index: 3;
                }
              }

              .approach_select_dropdown {
                @include flex-position(flex-start, flex-start);
                flex-direction: column;
                gap: 1rem;
                position: absolute;
                top: 50px;
                left: 0;
                width: 100%;
                border: 1px solid $color-grey-200;
                min-height: 100px;
                border-radius: 6px;
                padding: 1.5rem;
                background: $color-white;
                z-index: 5;
              }
            }

            .file_upload {
              @include flex-position(flex-start, center);
              flex-direction: column;
              gap: 1rem;
              padding-top: 1rem;

              .upload {
                @include flex-position(flex-start, center);
                flex-direction: column;
                gap: 10px;
                width: 100%;
                border: 1px solid $color-grey-200;
                border-radius: 12px;
                padding: 1rem;

                input {
                  display: none;
                }

                .upload_icon {
                  @include flex-position(center, center);
                  flex-direction: column;
                  width: 40px;
                  height: 40px;
                  border-radius: 10px;
                  border: 1px solid $color-grey-200;
                  cursor: pointer;

                  img {
                    align-self: center;
                  }
                }

                p {
                  margin: 0;
                  font-size: 14px;

                  span {
                    color: $color-primary-600;
                  }
                }
              }

              .uploaded_files {
                @include flex-position(flex-start, center);
                gap: 0.8rem;
                width: 100%;
                border: 1px solid $color-grey-200;
                border-radius: 12px;
                padding: 1rem;

                .file {
                  @include flex-position(flex-start, flex-start);
                  flex-direction: column;
                  gap: 5px;

                  p {
                    margin: 0;
                    font-size: 14px;
                  }

                  p:first-child {
                    font-weight: 500;
                  }
                }
              }
            }
          }
        }

        .btn {
          @include flex-position(flex-end, center);
          width: 100%;

          button {
            @include flex-position(center, center);
            width: 200px;
            height: 56px;
          }
        }
      }

      .brand_summary {
        .heading {
          border-bottom: 1px solid $color-grey-200;
          padding: 3rem 0 1.5rem;

          .title {
            font-size: 18px;
            font-weight: 600;
            color: $color-grey-900;
            margin-bottom: 0.5rem;
          }

          .sub_header {
            font-size: 14px;
          }
        }

        .summary_container {
          border: 1px solid $color-grey-200;
          border-radius: 12px;
          margin-top: 2rem;
          padding: 2rem;

          p {
            font-weight: 500;
            font-size: 16px;
            margin-bottom: 2rem;
          }

          .summary_content {
            border: 1px solid $color-grey-200;
            background: $color-grey-100;
            border-radius: 12px;
            width: 100%;
            height: 400px;
            overflow-y: auto;
            padding: 1.5rem;

            h1 {
              font-size: 16px;
              margin: 1rem 0;
            }

            p {
              font-size: 14px;
              line-height: 25px;
            }
          }
        }

        .update_summary {
          margin-top: 2rem;
          padding-bottom: 2.5rem;
          border-bottom: 1px solid $color-grey-200;

          p {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 8px;
          }

          .input {
            @include flex-position(flex-start, center);
            width: 100%;
            gap: 1rem;

            input {
              border: 1px solid $color-grey-200;
              background: none;
              border-radius: 8px;
              width: 100%;
              height: 47px;
              padding: 0 1rem;

              &:focus {
                border: 1px solid $color-primary-600 !important;
              }

              &::placeholder {
                color: $color-grey-400 !important;
              }
            }
          }
        }

        .save_btn {
          @include flex-position(flex-end, center);
          width: 100%;
          margin-top: 2rem;
        }
      }
    }

    .form {
      .title {
        margin-bottom: 24px;
        font-size: 1.1em;
        font-weight: 500;
        text-align: center;
      }

      .action .btn {
        margin-top: 42px;
        width: 100%;
      }
    }

    .profile_form {
      width: 100%;
      max-width: 360px;
    }

    .subscription_form {
      width: 100%;
      max-width: 420px;

      &.upgrade_form {
        max-width: 1200px;
      }

      .table {
        p {
          @include flex-position(space-between, center);
          gap: 12px;
          padding: 12px;
          font-size: 0.95em;
          font-weight: bold;
          border: 1px solid $color-grey-100;
          border-bottom: none;

          &:last-child {
            border-bottom: 1px solid $color-grey-100;
          }

          span {
            font-weight: 500;

            &.capitalise {
              text-transform: capitalize;
            }

            &.status {
              padding: 5px 10px;
              padding-bottom: 6px;
              font-size: 0.85em;
              text-transform: capitalize;
              background: $color-primary-50;
              border-radius: 3px;
            }
          }
        }
      }

      .upgrade {
        .periods {
          @include flex-position(center, center);
          margin-top: 42px;

          p {
            padding: 9px 24px;
            padding-bottom: 10px;
            font-size: 0.95em;
            font-weight: 500;
            background: $color-white;
            border: 1px solid $color-grey-100;
            cursor: pointer;

            &:last-child {
              border-left: none;
            }

            &.active {
              color: $color-white;
              background: $color-primary-600;
            }
          }
        }

        .page_link {
          @include flex-position(center, center);
          margin: auto;
          margin-top: 12px;
          font-size: 0.95em;
          font-weight: 500;
          color: $color-primary-600;
          width: fit-content;
        }

        .plans {
          @include grid-columns(repeat(4, 1fr), 16px);
          margin-top: 48px;

          @media (max-width: 1350px) {
            @include grid-columns(repeat(2, 1fr), 16px);
          }

          @media (max-width: 600px) {
            @include grid-columns(1fr, 18px);
          }

          .item {
            @include flex-position(flex-start, center);
            flex-direction: column;
            padding: 28px 16px;
            padding-bottom: 24px;
            text-align: center;
            border: 1px solid $color-grey-100;
            box-shadow: $box-shadow;

            .name {
              font-size: 1.4em;
              font-weight: bold;
            }

            .price {
              margin-top: 8px;
              font-size: 1.2em;
              font-weight: bold;
              color: $color-primary-600;

              span {
                font-size: 0.8em;
                font-weight: 500;
                color: $color-grey-900;
              }
            }

            .features {
              margin-top: 16px;
              margin-bottom: 24px;

              p {
                margin-top: 5px;
                font-size: 0.95em;
                color: $color-grey-900;

                span {
                  font-weight: 600;
                }

                &:first-child {
                  margin: 0;
                }
              }
            }

            .btn {
              margin: auto 0 0 0;
              width: 100%;

              .loader_img_container {
                margin: 0;

                img {
                  width: 28px;
                }
              }
            }
          }
        }

        .btn_tertiary {
          margin: auto;
          margin-top: 60px;
        }
      }
    }

    .password_form {
      width: 100%;
      max-width: 360px;
    }
  }
}