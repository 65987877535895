.customer_page_container {
  .total_customers {
    margin-bottom: 1.5rem;
    p {
      font-size: 14px;
    }
  }

  .table_container {
    @media (max-width: 996px) {
      .table {
        width: 634px;
      }
    }

    .customer_row {
      cursor: pointer;
      &:hover {
        background: $color-grey-200;
      }
    }

    .customer {
      @include flex-position(flex-start, center);
      gap: 12px;
      width: fit-content;
      text-decoration: none !important;

      .image {
        padding: 6px;
        padding-bottom: 3px;
        border-radius: 8px;
        background: $color-grey-50;

        img {
          width: 34px;
          height: 34px;
          object-fit: contain;
        }

        .initials {
          @include flex-position(center, center);
          width: 34px;
          height: 34px;
          padding-bottom: 2px;
          font-size: 1.3em;
        }
      }

      .name {
        text-transform: capitalize;
      }
    }
  }
}

.customer_info_page_container {
  .customer_info_page_inner {
    padding: 24px;
    border-radius: 16px;
    background: $color-white;

    @include smScreen {
      padding: 32px 16px;
    }

    .top_section {
      @include flex-position(space-between, flex-start);
      gap: 18px;
      flex-wrap: wrap;
      margin-bottom: 24px;
      width: 100%;

      .customer {
        @include flex-position(flex-start, center);
        gap: 12px;

        img {
          width: 98px;
          height: 98px;
          border-radius: 50%;
        }

        .image {
          padding: 6px;
          width: fit-content;
          border-radius: 8px;
          background: $color-grey-50;

          .initials {
            @include flex-position(center, center);
            margin: 0;
            width: 34px;
            height: 34px;
            padding-bottom: 2px;
            font-size: 1.3em;
          }
        }

        p {
          font-size: 1.3em;
          font-weight: 700;
          color: $color-grey-900;
          text-transform: capitalize;
        }
      }

      .info {
        @include flex-position(flex-start, flex-start);
        gap: 14px;

        img {
          width: 44px;
          height: 44px;
          object-fit: contain;
        }

        .side {
          .title {
            margin-bottom: 4px;
            font-size: 0.91em;
            font-weight: 500;
            color: $color-grey-400;
          }

          .details {
            font-size: 0.91em;
            font-weight: 600;
            color: $color-grey-900;
            line-height: 150%;
          }

          .amount {
            font-size: 1.05em;
            font-weight: 700;
            color: $color-grey-900;
          }
        }
      }

      .last_stack {
        .info:last-child {
          margin-top: 18px;
        }
      }
    }

    .insight {
      @include flex-position(flex-start, flex-start);
      margin-bottom: 48px;
      gap: 14px;

      img {
        width: 44px;
        height: 44px;
        object-fit: contain;
      }

      .side {
        .title {
          margin-bottom: 4px;
          font-size: 0.91em;
          font-weight: 500;
          color: $color-grey-400;
        }

        .details {
          font-size: 0.91em;
          font-weight: 600;
          color: $color-grey-900;
          line-height: 150%;
        }

        .generating {
          font-size: 0.91em;
          font-weight: 500;
          color: $color-grey-500;
          font-style: italic;
        }
      }
    }

    .history {
      margin-bottom: 20px;
      font-size: 0.975em;
      font-weight: 700;
      color: $color-grey-500;
    }

    .table_container {
      padding: 0;

      @media (max-width: 1040px) {
        .table {
          width: 868px;
        }
      }

      .product {
        @include flex-position(flex-start, center);
        gap: 12px;

        .image {
          padding: 6px;
          padding-bottom: 3px;
          border-radius: 8px;
          background: $color-grey-50;

          img {
            width: 34px;
            height: 34px;
            object-fit: contain;
          }
        }
      }

      tbody tr td {
        padding: 24px 16px;
      }
    }
  }
}
