.topbar_container {
  background: $color-white;
  padding: 0px 32px;

  @include lgScreen {
    padding: 0px 24px;
  }

  @include xsmdScreen {
    padding: 0px 12px;
  }

  .top_section {
    @include flex-position(space-between, center);
    padding: 16px 0px;
    border-bottom: 1px solid $color-grey-100;

    @include xsmdScreen {
      padding: 14.5px 0px;
    }

    .title {
      @include flex-position(flex-start, center);
      gap: 4rem;
      p {
        font-size: 1.35em;
        font-weight: 700;

        @include xsmdScreen {
          font-size: 1.25em;
        }
      }
    }

    .flex_end {
      @include flex-position(flex-end, center);
      gap: 32px;

      @include lgScreen {
        gap: 18px;
      }

      .form_group_container {
        margin: 0;
        max-width: 263px;

        @include lgScreen {
          max-width: 190px;
        }

        @include xsmdScreen {
          display: none;
        }

        .flex_input {
          padding: 10px 14px;

          .form-control {
            padding: 0 !important;
            font-size: 0.9em;
            font-weight: 400;
          }
        }
      }

      .notification {
        @include flex-position(center, center);
        position: relative;
        cursor: pointer;

        .alert {
          position: absolute;
          top: 2px;
          right: 2px;
          width: 8px;
          height: 8px;
          background: $color-pink-500;
          border: 1px solid $color-white;
          border-radius: 50%;
        }
      }

      .user {
        @include flex-position(flex-end, center);
        gap: 12px;

        @include xsmdScreen {
          gap: 6px;
        }

        .avatar {
          width: 35px;
          height: 40px;
          object-fit: contain;
        }

        .info {
          @include xsmdScreen {
            display: none;
          }

          .name {
            @include ellipsis;
            margin-bottom: 4px;
            font-size: 0.875em;
            font-weight: 700;
            max-width: 120px;
            color: $color-grey-900;
            text-transform: capitalize;
          }

          .role {
            @include ellipsis;
            font-size: 0.75em;
            font-weight: 400;
            max-width: 120px;
            color: $color-grey-500;
            text-transform: capitalize;
          }
        }

        .dropdown_container {
          width: fit-content;
        }
      }
    }
  }

  .bottom_section {
    @include flex-position(space-between, center);
    gap: 12px;
    flex-wrap: wrap;
    padding: 16px 0px;

    .filters {
      @include flex-position(flex-start, center);
      gap: 30px;

      .search_filter {
        position: relative;

        img {
          position: absolute;
          top: -31px;
          right: 10px;
          cursor: pointer;
        }
      }

      .button {
        padding: 8px 12px;
        font-size: 0.8em;
        font-size: 400;
        color: $color-grey-500;
        background: transparent;
        border-radius: 8px;
        cursor: pointer;

        &:hover {
          background: $color-primary-50;
        }

        &.active {
          font-weight: 700;
          color: $color-primary-600;
          background: $color-primary-50;
        }
      }

      .drop_select {
        @include flex-position(flex-start, center);
        background-color: $color-grey-50;
        border-radius: 12px;
        padding: 8px;
        gap: 10px;
        height: 100%;
        p {
          font-size: 12px;
          color: $color-grey-500;
        }

        .check-input {
          position: relative;
          margin-top: 0;
          margin-right: 0;
          width: 24px;
          height: 24px;
          border: 1px solid $color-grey-400;
          cursor: pointer;

          vertical-align: top;
          appearance: none;
          background: $color-white;
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          border-radius: 6.8px;

          &:focus {
            border: 1px solid $color-grey-400 !important;
          }

          &:checked {
            background-image: url("../../assets/svg/check.svg");
            background-color: $color-primary-600;
            border: $color-primary-600;
          }
        }
      }

      .form_group_container {
        margin: 0;
      }
    }

    .actions {
      @include flex-position(flex-end, center);
      gap: 20px;
      margin: 0 0 0 auto;

      .btn_primary {
        padding: 12px 16px;
        padding-bottom: 13px;
      }

      .dropdown-menu {
        top: 45px !important;

        .dropdown-item {
          &:first-child {
            color: $color-primary-600;
          }
        }
      }

      .layouts {
        @include flex-position(flex-end, center);
        gap: 4px;

        .item {
          @include flex-position(center, center);
          padding: 10px;
          background: transparent;
          border-radius: 10px;
          cursor: pointer;

          svg path {
            stroke: $color-grey-400;
          }

          &:hover {
            background: $color-grey-50;
          }

          &.active {
            background: $color-grey-50;

            svg path {
              stroke: $color-primary-600;
              stroke-width: 2;
            }
          }
        }
      }
    }
  }
}
