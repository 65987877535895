.coming-soon-container {
  height: 100vh;
  width: 100%;
  @include flex-position(center, center);
  .inner {
    .heading {
      font-size: 60px;
      font-weight: 600;
      line-height: 72px;
      margin-bottom: 1rem;
    }
    .sub-text {
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      color: $color-grey-500;

      span {
        color: $color-error;
      }
    }
  }
}
