.dash_home_page_container {
  width: 100%;

  .top_actions {
    .datepicker_container .react-datepicker-wrapper {
      width: 100px;

      .date_picker_input {
        font-weight: 500;
      }
    }
  }

  .grid_layout {
    @include grid-columns(repeat(2, 1fr), 48px);
    margin-top: 48px;
    margin-bottom: 48px;

    @include xsmdScreen {
      @include grid-columns(repeat(1, 1fr), 32px);
    }

    .video_update {
      video {
        @include flex-position(center, center);
        background: $color-grey-100;
        height: 350px;
        width: 100%;
        border-radius: 8px;

        @include xsmdScreen {
          height: auto;
        }
      }

      .title {
        margin-top: 8px;
        font-size: 0.95em;
        font-weight: 600;
      }

      .text {
        margin-top: 4px;
        font-size: 0.8em;
        color: $color-primary-600;
      }
    }

    .insights {
      padding: 18px;
      padding-bottom: 24px;
      background: $color-white;
      border-radius: 12px;
      height: 100%;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

      .insight {
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0;
        }

        .title {
          padding-bottom: 12px;
          margin-bottom: 12px;
          font-size: 0.95em;
          font-weight: 600;
          border-bottom: 1px solid $color-grey-100;
        }

        .list {
          p {
            margin-bottom: 10px;
            font-size: 0.85em;
            color: $color-grey-500;

            &:last-child {
              margin: 0;
            }
          }
        }
      }

      .see_all {
        @include flex-position(center, center);
        margin: 0 auto;
        margin-top: 18px;
        font-size: 0.9em;
        font-weight: 600;
        width: fit-content;
        cursor: pointer;
      }
    }
  }

  .chart_graph_container {
    padding: 24px;
    background: $color-white;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

    .top {
      @include flex-position(space-between, flex-start);
      gap: 12px;
      margin-bottom: 24px;
      border-bottom: 1px solid $color-grey-100;
      padding-bottom: 16px;

      p {
        font-size: 1em;
        font-weight: 600;
        color: $color-grey-900;
      }
    }

    .chart_filter {
      display: flex;
      flex-direction: row;
      gap: 16px;
      align-items: center;

      .datepicker_container {
        min-width: 240px;

        .react-datepicker-wrapper {
          width: 100%;

          input {
            width: 100%;
            height: 38px;
            border: 1px solid #d1d5db;
            border-radius: 6px;
            padding: 8px 12px;
            font-size: 0.95em;
            color: $color-grey-900;

            &:focus {
              outline: none;
              border-color: $color-primary-500;
              box-shadow: 0 0 0 1px $color-primary-500;
            }

            &::placeholder {
              color: $color-grey-400;
            }
          }
        }
      }

      .selection_container {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;

        .form_group_container {
          margin: 0;
          min-width: 160px;

          .select_container__control {
            min-height: 38px;
            height: 38px;
            padding: 2px 8px;
            font-size: 0.95em;
            border: 1px solid #d1d5db;
            border-radius: 6px;
            background-color: white;

            &:hover {
              border-color: #9ca3af;
            }

            &--is-focused {
              border-color: $color-primary-500 !important;
              box-shadow: 0 0 0 1px $color-primary-500 !important;
            }
          }

          .select_container__value-container {
            padding: 2px 6px;
          }

          .select_container__placeholder {
            color: $color-grey-400;
          }

          .select_container__single-value {
            color: $color-grey-900;
          }

          .select_container__menu {
            font-size: 0.95em;
            border: 1px solid #d1d5db;
            box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
              0 2px 4px -1px rgba(0, 0, 0, 0.06);
          }

          .select_container__option {
            padding: 8px 12px;

            &--is-focused {
              background-color: rgba($color-primary-500, 0.1);
            }

            &--is-selected {
              background-color: $color-primary-500;
              color: white;
            }
          }
        }
      }
    }

    .chart_container {
      position: relative;
      height: auto;
      width: 100%;
      margin-top: 24px;

      &.doughnut {
        @include flex-position(center, center);
        height: 250px;
      }
    }

    .no-data-message {
      @include flex-position(center, center);
      height: 200px;
      width: 100%;
      color: $color-grey-500;
      font-size: 0.95em;
      text-align: center;
      background: $color-grey-50;
      border-radius: 8px;
      margin-top: 24px;
    }
  }

  @media (max-width: 768px) {
    .chart_filter {
      flex-direction: column;
      align-items: stretch !important;

      .selection_container {
        flex-direction: column;
        width: 100%;

        .form_group_container {
          width: 100%;
        }
      }

      .datepicker_container {
        width: 100%;
      }
    }
  }

  .chart-container.loading,
  .chart-container.error,
  .chart-container.empty {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loading-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  .loading-spinner {
    width: 48px;
    height: 48px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #2563EB;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .loading-text {
    color: #666;
  }

  .error-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 24px;
    text-align: center;
  }

  .error-message {
    color: #dc2626;
    margin-bottom: 8px;
  }

  .retry-button {
    background: #2563EB;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }

  .retry-button:hover {
    background: #1d4ed8;
  }

  .empty-message {
    color: #666;
  }

  .chart-tooltip {
    background: white;
    padding: 16px;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .tooltip-title {
    font-weight: 600;
    margin-bottom: 8px;
  }

  .tooltip-content {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .tooltip-item {
    font-weight: 500;
  }
}