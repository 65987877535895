.content_container {
    display: block;
}

.name_container {
    margin: 25px 0 25px 0;

    .text_heading {
        font-weight: 500;
    }
}

.store_data_container {
    border: 1px solid $color-grey-100;
    border-radius: 15px;
    padding: 30px;
    margin: 40px 0;

    .data_container {
        width: 500px;

        @media (max-width: 768px) {
            width: 100%;
        }
    }
}

.url_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
}

.store_data_section {
    margin-top: 24px;

    .store_data_header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 10px;

        @media (max-width: 768px) {
            flex-direction: row;
            align-items: flex-start;
        }
    }

    .divider {
        width: 100%;
        color: $color-grey-400;
        opacity: 0.3;
        margin: 20px 0;
    }

    .other_container {
        display: flex;
        gap: 10px;
        align-items: center;

        @media (max-width: 768px) {
            flex-direction: column;
        }
    }

    .store_header_text {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 10px;

        @media (max-width: 768px) {
            font-size: 18px;
        }
    }

    .file-and-color-picker {
        display: flex;
        flex-direction: column;
        gap: 16px;
        font-family: Arial, sans-serif;
        color: #333;

        @media (max-width: 768px) {
            gap: 12px;
        }
    }

    .picker_container {
        width: 500px;
        border: 1px solid $color-grey-200;
        padding: 6px;
        border-radius: 8px;
        display: flex;
        gap: 4px;
        align-items: center;

        @media (max-width: 768px) {
            width: 100%;
            flex-wrap: wrap;
        }

        .file-label span {
            font-size: 14px;
            color: #333;
            text-align: center;
        }
    }

    .file-label {
        display: inline-block;
        background-color: #D9E4FD;
        color: $color-grey-900;
        padding: 8px 12px;
        border-radius: 4px;
        cursor: pointer;
        text-align: center;
        font-size: 14px;
        transition: background-color 0.3s ease;
    }

    .file-label:hover {
        background-color: #6eacee;
    }

    .file-input {
        display: none;
    }

    .color-picker-container {
        display: flex;
        gap: 16px;

        @media (max-width: 768px) {
            flex-direction: column;
        }
    }

    .color-picker button {
        background-color: #D9E4FD;
        border: 1px solid #84a5f1;
        border-radius: 4px;
        padding: 8px 12px;
        font-size: 14px;
        color: #333;
        cursor: pointer;
        transition: background-color 0.3s ease, border-color 0.3s ease;
    }

    .color-picker button:hover {
        background-color: #e0e0e0;
        border-color: #aaa;
    }

    .color-picker {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
    }

    .color-picker_label {
        font-size: 16px;
        line-height: 70px !important;
        font-weight: 500;
        color: #333;
    }

    input[type="color"] {
        display: none;
    }

    .store-data_btn-container {
        display: flex;
        width: 50px;
        height: 45px;
        max-height: 45px;
        justify-self: flex-end;
        justify-content: flex-end;

        @media (max-width: 768px) {
            width: 100%;
            justify-content: center;
            display: flex !important;
            flex-direction: row !important;
        }

        @media (max-width: 480px) {
            width: 100% !important;
            display: flex !important;
            flex-direction: row !important;
        }
    }
}

.custom-dropdown {
    position: relative;
    width: 100%;
    border: 1px solid $color-grey-200;
    border-radius: 8px;
    padding: 6px;
    font-family: Arial, sans-serif;

    .dropdown-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        cursor: pointer;
    }

    .dropdown-icon {
        color: inherit;
    }

    .dropdown-list {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        border: 1px solid #ccc;
        border-radius: 4px;
        z-index: 1000;
        max-height: 200px;
        overflow-y: auto;
    }

    .dropdown-items {
        padding: 10px;
        cursor: pointer;

        &:hover {
            background-color: #f0f0f0;
        }
    }
}