.question_container {
  @include flex-position(flex-start, center);
  flex-direction: column;
  padding: 50px;
  padding-top: 70px;
  width: 60%;
  max-height: 100vh;
  height: 100vh;
  overflow-y: auto;

  @include lgScreen {
    padding: 60px 20px;
  }

  @include mdScreen {
    padding: 40px 20px;
    width: 100%;
    height: 100%;
    min-height: 100%;
  }

  @include xsScreen {
    padding: 70px 12px;
  }

  .progress {
    width: 80%;
    max-width: 500px;
    margin: 2rem 0 3rem;
  }

  .question {
    width: 80%;

    max-width: 500px;

    .question_title {
      color: $color-grey-900;
      font-size: 22px;
    }

    .options_container {
      .options {
        padding-top: 2.5rem;
        flex-direction: column;

        @include flex-position(flex-start, left);
        gap: 24px;

        label {
          color: $color-grey-900;
          font-size: 18px;
          line-height: 27px;
        }
      }
    }
    .other_input {
      padding-top: 2.5rem;
      flex-direction: column;
      @include flex-position(flex-start, left);
      gap: 20px;
    }

    .action {
      padding-top: 5rem;
      @include flex-position(center, center);
      gap: 3rem;

      button {
        width: 150px;
        max-height: 45px;
      }
      .back_button {
        border: 1px solid $color-primary-600;
        background: none;
        color: $color-grey-900;
      }
    }
  }
}
