.sign_in_page {
  @include flex-position(flex-start, center);
  width: 100%;
  background: $color-white;

  .left_side {
    width: 45%;
    min-height: 100vh;
    height: 100vh;
    color: $color-white;
    background: $color-primary-600;

    @include mdScreen {
      display: none;
    }
  }

  .form_container {
    @include flex-position(center, center);
    flex-direction: column;
    padding: 40px;
    width: 55%;
    min-height: 100vh;
    height: 100vh;

    @include lgScreen {
      padding: 40px 20px;
    }

    @include mdScreen {
      padding: 70px 20px;
      width: 100%;
      height: 100%;
      min-height: 100%;
    }

    @include xsScreen {
      padding: 70px 12px;
    }

    .form {
      width: 100%;
      max-width: 404px;

      .title {
        margin-bottom: 8px;
        font-size: 1.5em;
        font-weight: 700;
        color: $color-grey-900;
      }

      .sub_title {
        margin-bottom: 30px;
        font-size: 0.95em;
        color: $color-grey-500;
        letter-spacing: 0px;
      }

      .form-group {
        input::placeholder {
          color: $color-grey-300 !important;
        }
      }

      .forgot {
        @include flex-position(space-between, center);

        .form_group_container {
          margin: 0;
        }

        label a {
          font-size: 1em;
          font-weight: 500 !important;
          color: $color-grey-900;
        }

        a {
          font-weight: 500 !important;
          font-size: 0.875em;
          color: $color-primary-600;
        }
      }

      .actions {
        margin-top: 32px;

        .btn {
          width: 100%;
        }

        .google_auth {
          margin-top: 32px;

          .rule_text {
            @include flex-position(flex-start, center);
            gap: 12px;
            width: 100%;

            .rule {
              height: 1px;
              background: $color-grey-200;
              width: inherit;
            }

            p {
              font-size: 0.8em;
              width: inherit;
              text-align: center;
              color: $color-grey-500;
            }
          }

          .btn_tertiary {
            margin-top: 32px;

            p {
              color: $color-grey-900;
              font-weight: 600;
            }
          }
        }

        .sign_up_text {
          margin-top: 32px;
          font-size: 0.875em;
          font-weight: 400;
          text-align: center;
          color: $color-grey-900;

          a,
          .link {
            font-weight: 700;
            color: $color-primary-600;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.sign_up_page {
  .form {
    .title {
      margin-bottom: 28px !important;
    }

    @media (max-height: 700px) {
      .title {
        margin-bottom: 20px !important;
      }

      .form_group_container {
        margin-bottom: 14px !important;
      }

      .actions {
        margin-top: 24px !important;

        .google_auth {
          margin-top: 24px !important;

          .btn_tertiary {
            margin-top: 24px !important;
          }
        }

        .sign_up_text {
          margin-top: 24px !important;
        }
      }
    }
  }
}
