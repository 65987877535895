.nav-tabs {
  @include flex-position(flex-start, flex-start);
  gap: 38px;
  flex-wrap: nowrap;
  width: 100%;
  border-bottom: 1px solid $color-grey-100;

  @media screen and (max-width: 1250px) {
    width: 935px;
  }

  .nav-item {
    width: auto;

    .nav-link {
      display: block;
      padding: 0;
      padding-bottom: 7px;
      font-size: 1em;
      color: $color-grey-500;
      border: none;
      border-bottom: 3px solid transparent;
      cursor: pointer;

      &:hover {
        border: none;
        border-bottom: 3px solid $color-primary-50;
      }

      &.active {
        font-weight: 500;
        color: $color-primary-600;
        background: transparent;
        border-bottom: 3px solid $color-primary-600;
      }
    }
  }
}
