.dropdown_container {
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: auto;
  // width: 100%;

  .drop {
    @include flex-position(flex-end, center);
    padding: 0;
    outline: none;
    border: none;
    background: transparent;
  }

  &.show {
    .dropdown-menu {
      display: block;
    }
  }

  .dropdown-menu {
    top: 24px !important;
    left: auto !important;
    right: 0px !important;
    padding: 0;
    background: $color-white;
    border: 1px solid $color-grey-50;
    border-radius: 16px;
    box-shadow: $box-shadow-2;
    transform: inherit !important;
    z-index: 1;
    display: none;

    @media screen and (max-width: 767px) {
      width: fit-content;
      width: -moz-fit-content;
    }

    .title {
      padding: 10px 12px;
      padding-bottom: 5px;
      font-size: 0.73em;
      color: rgba(132, 127, 133, 0.75);
      text-transform: uppercase;
    }

    .dropdown-item {
      padding: 12px 14px;
      font-size: 0.85em;
      font-weight: 500;
      min-width: 100px;
      text-wrap: nowrap;
      background: $color-white;
      color: $color-grey-400;
      cursor: pointer;

      &:hover,
      &:focus {
        color: $color-primary-600;
        background: $color-grey-50;
        outline: none;
      }

      &:first-child {
        border-radius: 8px 8px 0px 0px;
      }

      &:last-child {
        border-radius: 0px 0px 8px 8px;
      }
    }

    hr {
      margin-top: 8px;
      margin-bottom: 0;
    }
  }
}
