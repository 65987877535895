.pagination_container {
  @include flex-position(space-between, center);
  position: relative;
  margin-top: 16px !important;

  @media (max-width: 730px) {
    flex-direction: column;
    gap: 8px;
  }

  .rows {
    @include flex-position(flex-start, center);
    gap: 8px;
    position: relative;
    font-size: 0.9em;
    font-weight: 600;
    color: $color-grey-900;

    @media (max-width: 730px) {
      align-self: flex-start;
    }

    .form_group_container {
      font-size: 1.05em;

      .select_container__control {
        padding: 9px 10px;

        .select_container__single-value {
          color: $color-grey-400 !important;
        }
      }

      select {
        color: $color-grey-400;
      }

      .select_container__menu {
        top: auto !important;
        bottom: 100% !important;
      }
    }
  }

  .pagination {
    @include flex-position(flex-end, center);
    position: relative;
    gap: 6px;
    margin: 0;

    @media (max-width: 730px) {
      align-self: flex-end;
    }

    li {
      @include flex-position(center, center);
      cursor: pointer;

      a {
        @include flex-position(center, center);
        padding-bottom: 1px;
        text-decoration: none;
        font-size: 0.9em;
        outline: none;
        width: 40px;
        height: 40px;
        font-weight: 600;
        color: $color-grey-400;
        background: $color-white;
        border: 1px solid $color-grey-200;
        border-radius: 10px;

        svg {
          width: 24px;
          height: 24px;
          transform: rotate(90deg);

          path {
            stroke-width: 1.2;
          }
        }

        &:hover {
          color: $color-grey-400 !important;
          background-color: $color-primary-50;
        }
      }

      &:first-child {
        margin-right: 14px;
      }

      &:last-child {
        margin-left: 14px;
      }

      &.active a {
        color: $color-primary-600;
        background-color: $color-white;
      }

      &.disabled a {
        color: $color-grey-400;
        cursor: not-allowed;
        background-color: $color-grey-200;
      }

      &.next svg {
        transform: rotate(-90deg);
      }
    }
  }
}
