.ai_fillers_container {
  width: 100%;
  min-height: 20rem !important;
  padding: 4rem 6rem 5rem;
  .filler {
    @include flex-position(flex-start, center);
    flex-direction: column;
    gap: 3rem;

    p {
      color: $color-grey-500;
      font-size: 16px;
      font-weight: 500;
    }

    .icon_filler {
      @include flex-position(flex-start, center);
      flex-direction: column;
      gap: 1.2rem;
      p {
        font-size: 20px;
        font-weight: 400;
      }
    }
  }
}
