.accordion_container {
  width: 100%;
  border-radius: 12px;
  border: 1px solid $color-grey-100;

  .accordion_header {
    @include flex-position(space-between, center);
    padding: 12px 16px;
    width: 100%;
    cursor: pointer;

    .title {
      font-size: 0.85em;
      font-weight: 700;
      color: $color-grey-900;
    }

    svg {
      width: 18px;
      height: 18px;
      transform: rotate(180deg);
    }
  }

  .accordion_content {
    padding: 48px 16px;
    padding-bottom: 33px;
    border-top: 1px solid $color-grey-100;
    display: none;
  }

  &.open {
    .accordion_header svg {
      transform: rotate(0deg);
    }

    .accordion_content {
      display: block;
    }
  }
}
