.carousel_container {
  @include flex-position(space-around, center);
  flex-direction: column;
  padding: 40px;
  height: -webkit-fill-available;

  @include lgScreen {
    padding: 40px 20px;
  }

  .logo_container {
    position: absolute;
    top: 40px;
    left: 40px;

    @include lgScreen {
      left: 20px;
    }

    @include mdScreen {
      left: 12px;
    }
  }

  .section {
    margin-top: 34px;

    .slide {
      display: none;

      &.active {
        display: block;
      }

      img {
        width: 100%;

        @media (max-height: 720px) {
          height: 350px;
        }
      }

      .title {
        margin-top: 28px;
        font-size: 1.1em;
        font-weight: 700;
        text-align: center;
        line-height: 130%;
        color: $color-white;
      }

      .text {
        margin-top: 12px;
        font-size: 0.85em;
        text-align: center;
        font-weight: 200;
        color: $color-grey-50;
      }
    }
  }

  .top_section {
    margin-top: 34px;
  }

  .bottom_section {
    margin-top: 34px;

    .controls {
      @include flex-position(center, center);
      gap: 12px;
      margin-top: 35px;

      .item {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: $color-white;
        opacity: 0.2;
        cursor: pointer;

        &.active {
          background: $color-white;
          opacity: 1;
        }
      }
    }
  }
}
