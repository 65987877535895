.tag_container {
  @include flex-position(flex-start, center);
  background-color: $color-grey-50;
  padding: 8px 12px;
  gap: 10px;
  border-radius: 8px;
  .name {
    font-size: 12px;
    font-weight: 600;
    color: $color-grey-500;
  }
  .value {
    font-size: 10px;
    font-weight: 500;
    color: $color-grey-500;
  }
}
