.sidebar_container {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  background: $color-white;
  z-index: 1;

  .sidebar_container_inner {
    @include flex-position(flex-start, flex-start);
    flex-direction: column;
    padding: 0px;
    padding-bottom: 30px;
    height: 100vh;
    width: 60px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0px;
    }
    &::-webkit-scrollbar-thumb {
      width: 0px;
    }

    .logo_and_toggler {
      @include flex-position(flex-start, center);
      gap: 16px;
      padding: 20px 24px;
      width: 100%;

      .sidebar_toggler {
        @include flex-position(center, center);
        height: 29px;
        cursor: pointer;

        svg {
          width: 24px;
          height: 24px;
        }
      }

      .logo_container {
        img {
          width: auto;
          height: 29px;
        }
      }
    }

    hr {
      margin-bottom: 40px;
      width: 100%;
      height: 1px;
      background: $color-grey-100;
      border: none;
    }

    .links_container {
      width: 100%;

      .link {
        @include flex-position(flex-start, center);
        gap: 20px;
        margin-bottom: 8px;
        padding: 8px 0px;
        width: 100%;
        cursor: pointer;

        &:last-child {
          margin: 0;
        }

        .bar {
          width: 4px;
          height: 32px;
          border-radius: 0px 4px 4px 0px;
          background: transparent;
        }

        .icon_and_label {
          @include flex-position(flex-start, center);
          gap: 16px;
          padding: 5px 0px;
          font-size: 0.95em;
          font-weight: 500;
          color: $color-grey-500;

          svg {
            width: 22px;
            height: 22px;
          }
        }

        .count {
          padding: 4px 8px;
          font-size: 0.75em;
          font-weight: 500;
          color: $color-primary-600;
          background: $color-primary-50;
          border-radius: 4px;
        }

        &:hover {
          text-decoration: none;
          background: $color-primary-50;
        }

        &.active {
          .bar {
            background: $color-primary-600;
          }

          .icon_and_label {
            font-weight: 600;
            color: $color-primary-600;

            svg {
              path {
                stroke: $color-primary-600;
                stroke-width: 2;
              }
            }

            &.analytics {
              svg {
                path {
                  fill: $color-primary-600;
                  stroke: none;
                  stroke-width: 0;
                }
              }
            }
          }
        }

        &.logout {
          margin-top: 8px;
        }
      }
    }

    .bottom_links {
      margin-top: auto;
      margin-bottom: 40%;
      width: 100%;

      @media screen and (max-height: 610px) {
        margin-top: 32px;
        margin-bottom: 0;
      }
    }
  }

  &.sidebar_container_open {
    @media screen and (max-width: 850px) {
      &::after {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin-left: 250px;
        content: "";
        background: rgba(0, 0, 0, 0.45);
      }
    }

    .sidebar_container_inner {
      width: 250px;
    }
  }

  &.sidebar_container_closed {
    .logo_and_toggler {
      padding-left: 19px;
    }

    .links_container .link {
      gap: 16px;
    }

    .bottom_links {
      margin-bottom: 167%;

      @media screen and (max-height: 610px) {
        margin-bottom: 0;
      }
    }
  }
}
