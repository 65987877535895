.template-modal_container {
    padding: 20px;
    

    .modal-header {
        display: flex;
        flex-direction: row !important;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.5rem;

        .modal-title{
            margin-bottom: 1rem;
        }
    
        .action-icons {
            display: flex;
            gap: 10px;
        }
    
    }
}



.table-header-container {
    background-color: $color-grey-100 !important;
    border-top: 1px solid $color-grey-200;

    .temp-table-head {
        color: $color-grey-500;
        font-weight: 500;
    }
}

.templates-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
}

.templates-table th,
.templates-table td {
    padding: 1rem;
    text-align: left;
    // border-bottom: 1px solid #ddd;
}

.template-table-row{
    cursor: pointer;
    border-bottom: 1px solid #ddd;
    &:hover{
        background: $color-grey-100;
    }
  
    .template-name {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        width: 220px;
    
        .temp-name {
            font-size: 14px;
            font-weight: 500;
        }
    }
}



.temp-desc {
    font-size: 12px;
}

.template-image {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 4px;
}

.action-icon {
    cursor: pointer;
    color: #888;
}

.action-icon:hover {
    color: #333;
}